import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { editItemInfoApi } from "../../api/iteminfo";
import ListIcon from "../../assets/icons/list.svg";
import { calculatePercentage } from "../../global/helpers";
import useOrderOverviewModal from "../../hooks/useOrderOverviewModal";
import {
  bakersLevels,
  modifierCatData,
  orderOverViewComponentData,
  phasesId,
} from "../../utils/StaticData";
import NoData from "../common/NoData";
import OrderOverviewModal from "../modals/OrderOverviewModal";

const BakerList = (props) => {
  const { orders, ordersInfo, itemInfo, setItemInfo } = props;
  const [bakerListObj, setBakerListObj] = useState({});
  const [allordersInfoObj, setAllordersInfoObj] = useState({});
  const [
    handleOrderOverviewModal,
    orderOverviewModalShow,
    componentType,
    handleCloseModal,
    handleChangeOrderInfo,
  ] = useOrderOverviewModal(ordersInfo);

  const { nonTiered, tiered } = bakersLevels;

  // <------------------------------------------------------UseEffect Functions ------------------------------------------------------>

  const filterItemsByOthers = (items, key, property) => {
    let filteredItems = [];

    filteredItems = items.filter((item) => {
      const appInfo = item?.product?.app_info;

      return appInfo[property] === key;
    });
    return filteredItems;
  };

  const filterItemsByFlavor = (items, key) => {
    const filteredItems = items.filter((item) =>
      item?.modifieritems.some(
        (modiItem) =>
          modiItem?.modifier?.kitchen_print_name === key &&
          modifierCatData.flavor.includes(modiItem?.modifier?.modifierCat)
      )
    );
    return filteredItems;
  };

  const groupBy = (objectArray, property) => {
    return objectArray.reduce((accumulator, currentObject) => {
      let keys = [];
      if (property === "kitchen_print_name") {
        currentObject?.items.forEach((item) => {
          item?.modifieritems.forEach((modiItem) => {
            const modiId = modiItem?.modifier?.modifierCat;
            if (modifierCatData.flavor.includes(modiId)) {
              keys.push(modiItem?.modifier?.kitchen_print_name);
            }
          });
        });
      } else {
        currentObject?.items.forEach((item) => {
          const appInfo = item?.product?.app_info;

          appInfo[property] && keys.push(appInfo[property]);
        });
      }
      const uniqueKeys = [...new Set(keys)];
      uniqueKeys.forEach((key) => {
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        let filteredCurrentObj = {};
        if (property === "kitchen_print_name") {
          filteredCurrentObj = {
            ...currentObject,
            items: filterItemsByFlavor(currentObject?.items, key),
          };
        } else {
          filteredCurrentObj = {
            ...currentObject,
            items: filterItemsByOthers(currentObject?.items, key, property),
          };
        }

        accumulator[key].push(filteredCurrentObj);
      });
      return accumulator;
    }, {});
  };

  const nestGroupsBy = (arr, properties, prevKey) => {
    properties = Array.from(properties);
    if (properties.length === 1) {
      return groupBy(arr, properties[0]);
    }
    const property = properties.shift();
    var grouped = groupBy(arr, property, prevKey);
    for (let key in grouped) {
      grouped[key] = nestGroupsBy(grouped[key], Array.from(properties));
    }
    return grouped;
  };

  const filtereLevels = (orders, key) => {
    const filteredItemsinOrders = orders.map((order) => {
      const filteredItems = order?.items
        ? order?.items.filter((item) => {
            const appInfo = item?.product?.app_info;
            if (key === tiered) {
              return appInfo.ck_tiers > 1;
            } else {
              return appInfo.ck_tiers <= 1;
            }
          })
        : [];
      return {
        ...order,
        items: filteredItems,
      };
    });
    const filteredOrders = filteredItemsinOrders.filter(
      (order) => !isEmpty(order?.items)
    );
    return filteredOrders;
  };

  const getAllOrdersInfo = (ordersInfo) => {
    let tempAllOrderInfoObj = {};
    Object.entries(ordersInfo).forEach(([orderDate, data]) => {
      tempAllOrderInfoObj = { ...tempAllOrderInfoObj, ...data.orders };
    });
    return tempAllOrderInfoObj;
  };

  useEffect(() => {
    if (!isEmpty(orders) && !isEmpty(ordersInfo)) {
      const tieredOrders = filtereLevels(orders, tiered);
      const nonTieredOrders = filtereLevels(orders, nonTiered);
      const groups = nestGroupsBy(nonTieredOrders, [
        "kitchen_print_name",
        "ck_size",
        "ck_shape",
      ]);
      const tempBakerListobj = {
        [nonTiered]: groups,
        [tiered]: tieredOrders,
      };
      const tempAllOrderInfoObj = getAllOrdersInfo(ordersInfo);
      if (isEmpty(allordersInfoObj)) {
        setAllordersInfoObj(tempAllOrderInfoObj);
      }
      setBakerListObj(tempBakerListobj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, ordersInfo]);

  // <------------------------------------------------------Baked checkbox Functions ------------------------------------------------------>

  const handleBakedCheck = (itemInfoData, row) => {
    let check = false;
    if (
      itemInfoData?.current_phase &&
      itemInfoData?.current_phase.hasOwnProperty(row) &&
      itemInfoData?.current_phase[row]?.phase
    ) {
      check = itemInfoData?.current_phase[row]?.phase === phasesId?.baked;
    }
    return check;
  };

  const getotherRowData = (itemId, otherRow) => {
    const itemInfoData = itemInfo[itemId];
    if (
      itemInfoData &&
      itemInfoData.current_phase[otherRow] &&
      itemInfoData.current_phase[otherRow]?.phase === phasesId?.baked
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChangeBaked = async (itemId, row, e, rows) => {
    let otherRow;
    const updatedItemInfo = {
      ...itemInfo,
      [itemId]: {
        ...itemInfo[itemId],
        current_phase: {
          ...itemInfo[itemId]?.current_phase,
          [row]: { phase: e.target.checked ? phasesId.baked : null },
        },
      },
    };
    setItemInfo(updatedItemInfo);
    if (rows.length > 1) {
      otherRow = row === 1 ? 2 : 1;
    }

    const payload = {
      completed_phases: {
        [row]: {
          [phasesId.baked]: e.target.checked,
        },
        ...(rows.length > 1 && {
          [otherRow]: {
            [phasesId.baked]: getotherRowData(itemId, otherRow),
          },
        }),
      },
    };

    const res = await editItemInfoApi(payload, itemId);

    setItemInfo((prev) => {
      return {
        ...prev,
        [itemId]: res.data,
      };
    });
  };

  // <------------------------------------------------------Tiered Orders Functions ------------------------------------------------------>

  // <---------Batch Calculation for Tiered----------->
  const calculateBatchByName = (items) => {
    let sum = 0;
    items.forEach((item) => {
      item?.cake_items.forEach((cakeItem) => {
        const appInfo = cakeItem?.product?.app_info;
        sum += appInfo?.ck_batch * 2;
      });
    });
    return sum;
  };

  const calculateBatchForTiered = () => {
    let sum = 0;
    if (bakerListObj[tiered] && bakerListObj[tiered].length > 0) {
      bakerListObj[tiered].forEach((order) => {
        if (order?.items && order?.items.length) {
          sum += calculateBatchByName(order?.items);
        }
      });
    }
    return sum;
  };

  // <-----Baked Percentage Calulation for Tiered----->
  const getDataBakedPerForTiered = (order) => {
    let totalBaked = 0;
    let totalItems = 0;
    order?.items.forEach((item) => {
      const cakeItemsByFlavor = item?.cake_items.map((cakeItem) => {
        const modifierItemsByFlavor = cakeItem?.modifieritems.filter(
          (modiItem) =>
            modifierCatData.flavor.includes(modiItem?.modifier?.modifierCat)
        );
        return {
          ...cakeItem,
          modifieritems: modifierItemsByFlavor,
        };
      });
      cakeItemsByFlavor.forEach((cakeItem) => {
        totalItems += cakeItem.modifieritems.length;
        cakeItem.modifieritems.forEach((modiItem) => {
          const isBaked = handleBakedCheck(
            itemInfo[cakeItem.id],
            modiItem?.modifier?.modifierCat === 8 ? 1 : 2
          );

          if (isBaked) {
            totalBaked += 1;
          }
        });
      });
    });
    return [totalBaked, totalItems];
  };

  const getDataOverAllBakedPerForTiered = (tieredOrders) => {
    let overAllBakedItems = 0;
    let overAllItems = 0;
    tieredOrders.forEach((order) => {
      const [totalBaked, totalItems] = getDataBakedPerForTiered(order);
      overAllBakedItems += totalBaked;
      overAllItems += totalItems;
    });
    return [overAllBakedItems, overAllItems];
  };

  // <------------Render Tiered Order----------------->
  const renderTieredItems = (item, order) => {
    const cakeItemsByFlavor = item?.cake_items.map((cakeItem) => {
      const modifierItemsByFlavor = cakeItem?.modifieritems.filter((modiItem) =>
        modifierCatData.flavor.includes(modiItem?.modifier?.modifierCat)
      );
      return {
        ...cakeItem,
        modifieritems: modifierItemsByFlavor,
      };
    });

    return cakeItemsByFlavor.map((cakeItem) =>
      cakeItem.modifieritems.map((modiItem) => (
        <tr key={`${cakeItem.id} - ${modiItem.id}`}>
          <td>
            <div className="d-flex gap-2">
              <div
                onClick={() =>
                  handleOrderOverviewModal(
                    orderOverViewComponentData?.order,
                    order
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src={ListIcon} alt="list" height={22} />
              </div>
              <div>
                {order?.customer?.first_name + " " + order.customer?.last_name}
              </div>
            </div>
          </td>
          <td>{order?.local_id}</td>
          <td>E1</td>
          <td>{cakeItem?.product?.app_info?.ck_size}</td>
          <td>{cakeItem?.product?.app_info?.ck_shape.toUpperCase()}</td>
          <td>{modiItem?.modifier?.kitchen_print_name}</td>
          <td>{cakeItem?.product?.app_info?.ck_batch}</td>
          <td>
            <Form.Group>
              <Form.Check
                label=""
                onChange={(e) =>
                  handleOnChangeBaked(
                    cakeItem.id,
                    modiItem?.modifier?.modifierCat === 8 ? 1 : 2,
                    e,
                    cakeItem?.modifieritems
                  )
                }
                id={`baked-${cakeItem.id}-${modiItem.id}`}
                checked={handleBakedCheck(
                  itemInfo[cakeItem.id],
                  modiItem?.modifier?.modifierCat === 8 ? 1 : 2
                )}
                type="checkbox"
                className="custom-input-box checkbox-24"
                name={"baked"}
              />
            </Form.Group>
          </td>
        </tr>
      ))
    );
  };

  // <------------------------------------------------------Non-Tiered Orders Functions ------------------------------------------------------>

  // <-------Order Calculation for Non-Tiered-------->

  const calculateSumOrderForFlavour = (flavorOrders) => {
    let sum = 0;
    Object.values(flavorOrders).forEach((sizeOrders) => {
      Object.values(sizeOrders).forEach((shapeOrders) => {
        sum += calculateTotalSum(shapeOrders);
      });
    });
    return sum;
  };

  const calculateTotalSum = (shapeOrders) => {
    let sum = 0;
    shapeOrders.forEach((order) => {
      if (order.items.some((item) => item.product.app_info.ck_build === "dl")) {
        sum += 2;
      } else {
        sum += 1;
      }
    });
    return sum;
  };

  const calculateOrderSum = () => {
    let sum = 0;

    Object.values(bakerListObj[nonTiered]).forEach((flavorOrders) => {
      sum += calculateSumOrderForFlavour(flavorOrders);
    });

    return sum;
  };

  // <--------Batch Calculation for Non-Tiered-------->
  const calculateBatch = (arr, size, shape) => {
    const reqItem = arr.find((item) => {
      const appInfo = item?.product?.app_info;
      return appInfo?.ck_size === size && appInfo?.ck_shape === shape;
    });
    if (reqItem) {
      const appInfo = reqItem?.product?.app_info;
      let batchCount = 0;
      batchCount = appInfo?.ck_batch;

      return batchCount;
    } else {
      return 0;
    }
  };

  const calBatchForSummation = (arr, size, shape) => {
    const reqItem = arr.find((item) => {
      const appInfo = item?.product?.app_info;
      return appInfo?.ck_size === size && appInfo?.ck_shape === shape;
    });
    if (reqItem) {
      const appInfo = reqItem?.product?.app_info;
      let batchCount = 0;
      batchCount = appInfo?.ck_batch;

      if (appInfo?.ck_build === "dl") {
        batchCount *= 2;
      }

      return batchCount;
    } else {
      return 0;
    }
  };

  const calculateTotalBatchForFlavour = (flavorOrders) => {
    let sum = 0;
    Object.entries(flavorOrders).forEach(([sizeType, sizeOrders]) => {
      Object.entries(sizeOrders).forEach(([shapeType, shapeOrders]) => {
        sum += calculateTotalBatch(shapeOrders, sizeType, shapeType);
      });
    });
    return sum;
  };

  const calculateTotalBatch = (shapeOrders, size, shape) => {
    if (shapeOrders) {
      const totalBatch = shapeOrders.reduce((acc, currValue) => {
        const singleBatch = calBatchForSummation(currValue?.items, size, shape);

        return acc + singleBatch;
      }, 0);
      return totalBatch;
    }
  };

  const calculateOverAllBatchSum = (bakerListObj) => {
    let sum = 0;

    Object.entries(bakerListObj).forEach(([flavorType, flavorOrders]) => {
      sum += calculateTotalBatchForFlavour(flavorOrders);
    });

    return sum;
  };

  // <---Baked Percentage Calulation for Non-Tiered--->
  const getDataBakedPerForShape = (orders) => {
    let totalBaked = 0;
    let totalItems = 0;
    orders.forEach((order) => {
      let rows = [1];
      order?.items?.forEach((item) => {
        if (item?.product?.app_info?.ck_build === "dl") {
          rows.push(2);
        }
        totalItems += rows.length;
        rows.forEach((row) => {
          const isBaked = handleBakedCheck(itemInfo[item?.id], row);
          if (isBaked) {
            totalBaked += 1;
          }
        });
      });
    });
    return [totalBaked, totalItems];
  };

  const getDataBakedPerForFlavour = (flavorOrders) => {
    let totalBakedForFlavour = 0;
    let totalItemsForFlavour = 0;
    Object.values(flavorOrders).forEach((sizeOrders) => {
      Object.values(sizeOrders).forEach((shapeOrders) => {
        const [totalBaked, totalItems] = getDataBakedPerForShape(shapeOrders);
        totalBakedForFlavour += totalBaked;
        totalItemsForFlavour += totalItems;
      });
    });
    return [totalBakedForFlavour, totalItemsForFlavour];
  };

  const getDataOverAllBakedPerForNonTiered = (nonTieredObj) => {
    let overAllBakedItems = 0;
    let overAllItems = 0;
    Object.values(nonTieredObj).forEach((flavorOrders) => {
      const [totalBaked, totalItems] = getDataBakedPerForFlavour(flavorOrders);
      overAllBakedItems += totalBaked;
      overAllItems += totalItems;
    });
    return [overAllBakedItems, overAllItems];
  };

  // <------------Render Non-Tiered Order------------->
  const renderNonTieredOrders = (order, sizeType, shapeType, flavorType) => {
    let rows = [1];
    return order.items?.map((item) => {
      if (item?.product?.app_info?.ck_build === "dl") {
        rows.push(2);
      }
      return rows.map((row, index) => (
        <tr key={`${order.id}-${index}`}>
          <td>
            <div className="d-flex gap-2">
              <div
                onClick={() =>
                  handleOrderOverviewModal(
                    orderOverViewComponentData?.order,
                    order
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <img src={ListIcon} alt="list" height={22} />
              </div>
              <div>
                {order?.customer?.first_name + " " + order?.customer?.last_name}
              </div>
            </div>
          </td>
          <td>{order?.local_id}</td>
          <td>E1</td>
          <td>{sizeType}</td>
          <td>{shapeType.toUpperCase()}</td>
          <td>{flavorType}</td>
          <td>{calculateBatch(order?.items, sizeType, shapeType)}</td>
          <td>
            <Form.Group>
              <Form.Check
                label=""
                onChange={(e) => handleOnChangeBaked(item?.id, row, e, rows)}
                id={`baked-${item.id}-${row}`}
                checked={handleBakedCheck(itemInfo[item?.id], row)}
                type="checkbox"
                className="custom-input-box checkbox-24"
                name={"baked"}
              />
            </Form.Group>
          </td>
        </tr>
      ));
    });
  };

  return (
    <Table
      responsive
      className="editable-table custom-table-striped multi-level-head"
    >
      <colgroup>
        <col width={220} />
        <col width={140} />
        <col width={80} />
        <col width={80} />
        <col width={120} />
        <col width={120} />
        <col width={120} />
        <col width={140} />
      </colgroup>
      <thead className="border-0">
        <tr>
          <th>Name</th>
          <th>Orders #</th>
          <th>S#</th>
          <th>Size</th>
          <th>Shape</th>
          <th>Flavor</th>
          <th>Batch</th>
          <th>Baked</th>
        </tr>
      </thead>
      <tbody>
        <>
          <OrderOverviewModal
            show={orderOverviewModalShow}
            onHide={handleCloseModal}
            componenttype={componentType}
            handleChangeOrderInfo={handleChangeOrderInfo}
          />

          {isEmpty(bakerListObj) ? (
            <tr className="bg-white">
              <td colSpan={8}>
                <NoData />
              </td>
            </tr>
          ) : (
            <>
              {/* None-Tiered */}

              <tr className="non-tiered">
                <th>{nonTiered}</th>
                <th>{`Sum: ${calculateOrderSum()}`}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{`Total: ${Number(
                  calculateOverAllBatchSum(bakerListObj[nonTiered])
                ).toFixed(2)}`}</th>

                <th>{`${calculatePercentage(
                  bakerListObj[nonTiered],
                  getDataOverAllBakedPerForNonTiered
                ).toFixed(2)}%`}</th>
              </tr>
              {!isEmpty(bakerListObj[nonTiered]) ? (
                Object.entries(bakerListObj[nonTiered]).map(
                  ([flavorType, flavorOrders]) => {
                    return (
                      <React.Fragment key={flavorType}>
                        <tr className="product-flavor">
                          <th>{flavorType}</th>
                          <th>{`Sum: ${calculateSumOrderForFlavour(
                            flavorOrders
                          )}`}</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>{`Total: ${Number(
                            calculateTotalBatchForFlavour(flavorOrders)
                          ).toFixed(2)}`}</th>
                          <th>{`${calculatePercentage(
                            flavorOrders,
                            getDataBakedPerForFlavour
                          ).toFixed(2)}%`}</th>
                        </tr>
                        {Object.entries(flavorOrders).map(
                          ([sizeType, sizeOrders]) => {
                            return (
                              <React.Fragment key={sizeType}>
                                {Object.entries(sizeOrders).map(
                                  ([shapeType, shapeOrders]) => {
                                    return (
                                      <React.Fragment key={shapeType}>
                                        <tr>
                                          <th>{`${sizeType}" ${flavorType} ${shapeType.toUpperCase()}`}</th>
                                          <th>
                                            Sum:{" "}
                                            {calculateTotalSum(shapeOrders)}
                                          </th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          <th>
                                            Total:{" "}
                                            {Number(
                                              calculateTotalBatch(
                                                shapeOrders,
                                                sizeType,
                                                shapeType
                                              )
                                            ).toFixed(2)}
                                          </th>
                                          <th>
                                            {`${calculatePercentage(
                                              shapeOrders,
                                              getDataBakedPerForShape
                                            ).toFixed(2)}%`}
                                          </th>
                                        </tr>
                                        {shapeOrders &&
                                          !isEmpty(shapeOrders) &&
                                          shapeOrders.map((order) => {
                                            return renderNonTieredOrders(
                                              order,
                                              sizeType,
                                              shapeType,
                                              flavorType
                                            );
                                          })}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  }
                )
              ) : (
                <tr>
                  <th colSpan={8} className="text-center">
                    No Non-Tiered cakes available
                  </th>
                </tr>
              )}

              {/* Tiered */}

              <tr className="tiered">
                <th>{tiered}</th>
                <th>{`Sum: ${
                  bakerListObj[tiered] ? bakerListObj[tiered].length : 0
                }`}</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>{`Total: ${Number(calculateBatchForTiered()).toFixed(
                  3
                )}`}</th>
                <th>{`${calculatePercentage(
                  bakerListObj[tiered],
                  getDataOverAllBakedPerForTiered
                ).toFixed(2)}%`}</th>
              </tr>
              {!isEmpty(bakerListObj[tiered]) ? (
                bakerListObj[tiered].map((order) => (
                  <React.Fragment key={order.id}>
                    <tr>
                      <th>
                        {order?.customer?.first_name +
                          " " +
                          order.customer?.last_name}
                      </th>
                      <th>{order?.local_id}</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total: {calculateBatchByName(order?.items)}</th>
                      <th>{`${calculatePercentage(
                        order,
                        getDataBakedPerForTiered
                      ).toFixed(2)}%`}</th>
                    </tr>
                    {order?.items.map((item) => renderTieredItems(item, order))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <th colSpan={8} className="text-center">
                    No Tiered cakes available
                  </th>
                </tr>
              )}
            </>
          )}
        </>
      </tbody>
    </Table>
  );
};

export default BakerList;
