export const templateArr = [
  {
    id: 3,
    templateName: "Orders.jsx",
  },
  {
    id: 1,
    templateName: "Cat-Grid.jsx",
  },
  {
    id: 4,
    templateName: "Hourly.jsx",
  },
  {
    id: 5,
    templateName: "Baker-List.jsx",
  },
  {
    id: 6,
    templateName: "Production.jsx",
  },
];
