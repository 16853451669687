import React from "react";
import Login from "../../components/Auth/Login";

const Auth = (props) => {
  return (
    <div>
      <Login />
    </div>
  );
};
export default Auth;
