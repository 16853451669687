export const routesConstant = {
  LOGIN: "/login",
  HOME: "/home",
  Pages: "/pages",
  Settings: "/settings",
  Phases: "/phases",
  Templates: "/templates",
  ConfigTemplateOption: "/config-templates",
  ConfigureUser: "/configure-user",
  ConfigureLevel: "/configure-level",
  CreateLevels: "/create-levels",
  configHomePage: "/config-home-page",
  productPage: "/:parentPage/:childPage/",
  configCategoryPage: "/config-category-pages",
  orderOverview: "/order-overview",
  orderOverviewCategory: "/order-overview-category/:categoryType/:orderId",
  cakeFillings: "/cake-fillings",
  cakeFillingsSetting: "/cake-fillings-setting",
};
