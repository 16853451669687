import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { setToken } from "../api";
import { getAllItemInfoByDaysApi } from "../api/iteminfo";
import { getAllOrdersByDaysApi, getOrderInfoApi } from "../api/orders";
import { orderToken } from "../utils/StaticData";

const useCallOrder = (
  calenderDates,
  setLoader,
  allSubPagesConfig,
  allPages,
  allTemplates
) => {
  const [ordersByDate, setOrdersByDate] = useState({});
  const [ordersInfo, setOrdersInfo] = useState({});
  const [itemInfo, setItemInfo] = useState({});

  const params = useParams();
  const location = useLocation();
  const { parentPage } = params;
  const { pathname } = location;
  const pathArray = pathname.split("/");
  const isCategoryProducts = pathArray.includes(parentPage);

  const { startDate, days } = calenderDates;

  const getAllOrdersByDays = async (date, days) => {
    setToken(orderToken);
    const res = await getAllOrdersByDaysApi(date, days);
    if (res && res.success === true) {
      setOrdersByDate(res.data || {});
    }
  };

  const getOrderInfo = async (startDate, days) => {
    setToken(orderToken);
    const res = await getOrderInfoApi(startDate, days);
    if (res && res.success === true) {
      setOrdersInfo(res.data || {});
    }
  };

  const getAllItemInfoByDays = async (date, days) => {
    setToken(orderToken);
    const res = await getAllItemInfoByDaysApi(date, days);
    if (res && res.success === true) {
      setItemInfo(res.data || {});
    }
  };

  const callOrdersApi = useCallback(async () => {
    setLoader(true);
    if (startDate) {
      await getOrderInfo(startDate, days);
      if (isCategoryProducts) {
        await getAllItemInfoByDays(startDate, days);
      }
      await getAllOrdersByDays(startDate, days);
    }
    setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, days]);

  useEffect(() => {
    if (isCategoryProducts) {
      if (
        !isEmpty(allSubPagesConfig) &&
        !isEmpty(allTemplates) &&
        !isEmpty(allPages)
      ) {
        callOrdersApi();
      }
    } else {
      callOrdersApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubPagesConfig, allPages, callOrdersApi, allTemplates]);

  return [ordersByDate, ordersInfo, itemInfo, setItemInfo, setOrdersInfo];
};

export default useCallOrder;
