import React from "react";
import Layout from "../common/layout";
import Calculator from "../common/Calculator";
import { useState } from "react";
import { Container } from "react-bootstrap";

const Fillings = () => {
  const [flavour, setFlavour] = useState("");

  const handleSelectFlavour = (e) => {
    setFlavour((prev) => {
      if (prev === e.target.value) {
        return "";
      }
      return e.target.value;
    });
  };

  return (
    <>
      <Layout>
        <Container>
          <h6 className="text-primary h3 border-bottom-primary borde-primary font-weight-bold mb-3 pb-2">
            Bakers Calculator: Cupcakes
          </h6>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="pt-4 tableSize ">
              <section className="py-2 overflow-auto">
                <table>
                  <colgroup>
                    <col width={250} />
                    <col width={520} />
                    <col width={70} />
                  </colgroup>
                  <tbody className="grid-table-body">
                    <tr className="px-2">
                      <td>
                        <table className="cat-grid grid-table-striped border-0 filling_table">
                          <colgroup>
                            <col width={180} />
                            <col width={60} />
                          </colgroup>
                          <tbody className="grid-table-body ">
                            <tr>
                              <th>&nbsp;</th>
                            </tr>
                            <tr></tr>
                            <tr>
                              <th></th>
                              <th className="p-1 border border-dark text-center">
                                Total
                              </th>
                            </tr>
                            <tr>
                              <td
                                className={`  ${
                                  flavour === "Chocolate"
                                    ? "flavour "
                                    : "border border-dark"
                                }`}
                              >
                                <button
                                  className="filling_btn border-box-right fw-bold mx-1"
                                  onClick={handleSelectFlavour}
                                  value="Chocolate"
                                >
                                  Chocolate
                                </button>
                              </td>
                              <td className="p-1 border border-dark text-center">
                                100
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={`  ${
                                  flavour === "White"
                                    ? "flavour "
                                    : "border border-dark"
                                }`}
                              >
                                <button
                                  className="filling_btn border-box-right fw-bold mx-1 "
                                  onClick={handleSelectFlavour}
                                  value="White"
                                >
                                  White
                                </button>
                              </td>
                              <td className="p-1 border border-dark text-center">
                                100
                              </td>
                            </tr>
                            <tr>
                              <td
                                className={`  ${
                                  flavour === "Red"
                                    ? "flavour "
                                    : "border border-dark"
                                }`}
                              >
                                <button
                                  className="filling_btn border-box-right fw-bold mx-1"
                                  onClick={handleSelectFlavour}
                                  value="Red"
                                >
                                  Red
                                </button>
                              </td>
                              <td className="p-1 border border-dark text-center">
                                100
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td>
                        <table className="text-center">
                          <colgroup>
                            <col width={130} />
                            <col width={130} />
                            <col width={130} />
                            <col width={130} />
                          </colgroup>
                          <tbody className="grid-table-body">
                            <tr>
                              <td className="px-2">
                                <table className="table-bordered cat-grid grid-table-striped filling_table">
                                  <colgroup>
                                    <col width={60} />
                                    <col width={60} />
                                  </colgroup>
                                  <tbody className="grid-table-body ">
                                    <tr>
                                      <th colSpan="2" className="border-0">
                                        9 am
                                      </th>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <th className="border border-dark">
                                        Order
                                      </th>
                                      <th className="p-1 border border-dark">
                                        Done
                                      </th>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="px-2">
                                <table className="table-bordered cat-grid grid-table-striped filling_table">
                                  <colgroup>
                                    <col width={60} />
                                    <col width={60} />
                                  </colgroup>
                                  <tbody className="grid-table-body">
                                    <tr>
                                      <th colSpan="2" className="border-0">
                                        12 pm
                                      </th>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td className="border border-dark">
                                        Order
                                      </td>
                                      <td className="p-1 border border-dark">
                                        Done
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="px-2">
                                <table className="table-bordered cat-grid grid-table-striped filling_table">
                                  <colgroup>
                                    <col width={60} />
                                    <col width={60} />
                                  </colgroup>
                                  <tbody className="grid-table-body">
                                    <tr>
                                      <th colSpan="2" className="border-0">
                                        2 pm
                                      </th>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td className="border border-dark">
                                        Order
                                      </td>
                                      <td className="p-1 border border-dark">
                                        Done
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="px-2">
                                <table className="table-bordered cat-grid grid-table-striped filling_table">
                                  <colgroup>
                                    <col width={60} />
                                    <col width={60} />
                                  </colgroup>
                                  <tbody className="grid-table-body">
                                    <tr>
                                      <th colSpan="2" className="border-0">
                                        Sunday
                                      </th>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td className="border border-dark">
                                        Order
                                      </td>
                                      <td className="p-1 border border-dark">
                                        Done
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark highlight-lightgreen">
                                        10
                                      </td>
                                      <td className="p-1 border border-dark highlight-lightgreen">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="border border-dark">10</td>
                                      <td className="p-1 border border-dark">
                                        20
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className=" px-2 text-center">
                        <table className="table-bordered cat-grid grid-table-striped filling_table">
                          <colgroup>
                            <col width={60} />
                          </colgroup>
                          <tbody className="grid-table-body ">
                            <tr>
                              <th className="border-0">&nbsp;</th>
                            </tr>
                            <tr></tr>
                            <tr>
                              <th className="p-1 border border-dark">Extra</th>
                            </tr>
                            <tr>
                              <td className="p-1 border border-dark">100 </td>
                            </tr>
                            <tr>
                              <td className="p-1 border border-dark">100</td>
                            </tr>
                            <tr>
                              <td className="p-1 border border-dark">100</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <div className="labelStyle" style={{ width: "100%" }}>
                <label className="p-2"> Large Cupcakes</label>
              </div>
            </div>
            <div className="pt-4">
              <Calculator flavour={flavour} />
            </div>
          </div>
        </Container>
      </Layout>
    </>
  );
};

export default Fillings;
