import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

//apis
import { setToken } from "../../api";
import { getPagesApi } from "../../api/pages";
import { getPhasesApi } from "../../api/phases";
import { getTemplatesApi } from "../../api/templates";
import { getProductsCategoriesIdApi } from "../../api/orders";
import {
  getAllSubPagesConfigApi,
  getSubPageDiningConfigByIDApi,
} from "../../api/configCategory";

//redux
import { updateAllPages } from "../../redux/actions/pageActions";
import { getUpdatePhases } from "../../redux/actions/phaseAction";
import { addProductCategories } from "../../redux/actions/orderActions";
import { updateAllTemplates } from "../../redux/actions/templateActions";
import { updateAllSubPageConfig } from "../../redux/actions/subPageConfigActions";

//helpers and static datas
import { templateArr } from "../../utils/templateData";
import { displayErrorToast } from "../../global/displayToast";
import { getCheckboxFilteredOrder } from "../../global/helpers";
import { orderToken, phasesId } from "../../utils/StaticData";
import {
  initialBakedFilterState,
  initialCommonFilterState,
  initialProductionFilterState,
} from "../../utils/filterData";

//hooks
import useCallOrder from "../../hooks/useCallOrder";
import useCalenderSearchQuery from "../../hooks/useCalenderSearchQuery";

//components
import Orders from "../../components/templates/Orders";
import CatGrid from "../../components/templates/CatGrid";
import Hourly from "../../components/templates/Hourly";
import BakerList from "../../components/templates/BakerList";
import Production from "../../components/templates/Production";
import SearchInput from "../../components/common/SearchInput";
import OrderLayout from "../../components/common/OrderLayout";
import FilterModal from "../../components/modals/FilterModal";
import CakeSpinner from "../../components/common/CakeSpinner";
import ProductionFilterModal from "../../components/modals/ProductionFilterModal";
import BakerFilterModal from "../../components/modals/BakerFilterModal";

const ProductPage = () => {
  const [loader, setLoader] = useState(false);
  const [productCatName, setProductCatName] = useState("");
  const [showSubpage, setShowSubPage] = useState({});
  const [filterModalShow, setFilterModalShow] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categoryOrders, setCategoryOrders] = useState({});
  const [subPagePublicNotes, setSubPagePublicNotes] = useState("");
  const [publicNotesLoader, setPublicNotesLoader] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    initialCommonFilterState
  );
  const [productionFilters, setProductionFilters] = useState(
    initialProductionFilterState
  );
  const [currentdropDownValue, setCurrentDropDownValue] = useState({});
  const [filterDropdown, setFilterDropdown] = useState([
    {
      filter: "fillings",
      filterValue: [],
    },
    {
      filter: "iced",
      filterValue: [],
    },
  ]);
  const [bakerFilter, setBakerFilter] = useState(initialBakedFilterState);

  const { token } = useSelector((state) => state.user);
  const { allPages } = useSelector((state) => state.pages);
  const { allTemplates } = useSelector((state) => state.templates);
  const { allSubPagesConfig } = useSelector((state) => state.subPageConfig);
  const { productCategories } = useSelector((state) => state.orders);
  const { phasesList } = useSelector((state) => state.phases);

  const dispatch = useDispatch();

  const params = useParams();
  const { childPage, parentPage } = params;

  const [calenderDates, setCalenderDates] = useCalenderSearchQuery();
  const [ordersByDate, ordersInfo, itemInfo, setItemInfo, setOrdersInfo] =
    useCallOrder(
      calenderDates,
      setLoader,
      allSubPagesConfig,
      allPages,
      allTemplates
    );

  const getSubPageDiningConfigByID = async (id) => {
    const res = await getSubPageDiningConfigByIDApi(id);
    if (res && res.success) {
      setSubPagePublicNotes(res.data[0]?.notes);
    } else {
      displayErrorToast(res.message);
    }
    setPublicNotesLoader(false);
  };

  const getupdatedItem = (orderDetail, productRevelsId) => {
    const updatedItem = orderDetail?.items
      ? orderDetail?.items
          .filter((item) => {
            if (item?.cake_items) {
              let cakeItems = item?.cake_items.filter((cake_item) => {
                const check =
                  productRevelsId.includes(
                    (cake_item?.product?.id).toString()
                  ) || productRevelsId.includes((item?.product?.id).toString());
                return check;
              });
              if (isEmpty(cakeItems)) {
                return false;
              } else {
                return true;
              }
            } else {
              const check = productRevelsId.includes(
                (item?.product?.id).toString()
              );
              return check;
            }
          })
          .map((item) => {
            if (item?.cake_items && !isEmpty(item?.cake_items)) {
              let cakeItems = item?.cake_items.map((cakeItem) => {
                if (itemInfo[cakeItem.id]) {
                  return {
                    ...cakeItem,
                    ...itemInfo[cakeItem.id],
                  };
                } else {
                  return {
                    ...cakeItem,
                  };
                }
              });
              if (itemInfo[item.id]) {
                return {
                  ...item,
                  ...itemInfo[item.id],
                  cake_items: cakeItems,
                };
              } else {
                return {
                  ...item,
                  cake_items: cakeItems,
                };
              }
            }

            if (itemInfo[item.id]) {
              return {
                ...item,
                ...itemInfo[item.id],
              };
            } else {
              return {
                ...item,
              };
            }
          })
      : [];
    return updatedItem;
  };

  const filterByCategory = (orders) => {
    const allRevelsId = [];
    allSubPagesConfig.forEach((subPageConfig) => {
      if (subPageConfig.page_id === showSubpage?.subPageId) {
        allRevelsId.push(subPageConfig?.revel_ids);
      }
    });

    const productRevelsId = allRevelsId.flat().filter((ele) => ele !== "");
    const ordersByCategory = Object.entries(orders).map(
      ([orderDate, orderData]) => {
        let filteredOrders = [];
        if (orderData?.orders) {
          filteredOrders = Object.entries(orderData?.orders).map(
            ([orderId, orderDetail]) => {
              return [
                orderId,
                {
                  ...orderDetail,
                  items: getupdatedItem(orderDetail, productRevelsId),
                },
              ];
            }
          );
        }
        const removedEmptyItemOrderfilteredOrders = filteredOrders.filter(
          ([orderId, orderDetail]) => !isEmpty(orderDetail?.items)
        );

        return [
          orderDate,
          {
            ...orderData,
            orders: Object.fromEntries(removedEmptyItemOrderfilteredOrders),
          },
        ];
      }
    );
    const filteredOrderObj = Object.fromEntries(ordersByCategory);
    setCategoryOrders(filteredOrderObj);
  };

  useEffect(() => {
    if (ordersByDate && !isEmpty(showSubpage)) {
      filterByCategory(ordersByDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersByDate, showSubpage.subPageId]);

  const getAllSubPagesConfig = async () => {
    setToken(token);
    const res = await getAllSubPagesConfigApi();
    if (res && res.success === true) {
      dispatch(updateAllSubPageConfig(res.data));
    }
  };

  const handleSort = (subPages) =>
    subPages.sort((a, b) => {
      if (a?.sort !== b?.sort) return a?.sort - b?.sort;
      else
        return a?.display_name
          .replace(/\s/g, "")
          .localeCompare(b?.display_name.replace(/\s/g, ""));
    });

  const changeString = (text) => {
    const tempstring = text
      .toLowerCase()
      .replace(/-/g, " ")
      .replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      })
      .replace(/By/, "by");
    return tempstring;
  };

  const handleShowSubPage = (pages) => {
    const strChild = changeString(childPage);
    const strParent = changeString(parentPage);

    const tempParentpage = pages.find(
      (page) => page.display_name === strParent
    );

    const tempShowSubPage = pages.find(
      (page) =>
        page.display_name === strChild && page.sub_page === tempParentpage.id
    );

    if (tempShowSubPage?.sub_page) {
      const tempSubPages = pages.filter(
        (page) => page?.sub_page === tempShowSubPage?.sub_page
      );

      const tempProductCatName = pages.find(
        (page) => page.id === tempShowSubPage?.sub_page
      )?.display_name;

      setProductCatName(tempProductCatName);
      setShowSubPage({
        subPages: handleSort(tempSubPages),
        templateId: tempShowSubPage?.template_id,
        subPageName: tempShowSubPage?.display_name,
        subPageId: tempShowSubPage?.id,
      });
    } else {
      const tempSubPages = pages.filter(
        (page) => page?.sub_page === tempShowSubPage?.id
      );

      const tempProductCatName = pages.find(
        (page) => page.display_name === strChild
      )?.display_name;

      setProductCatName(tempProductCatName);

      const sortedSubPages = handleSort(tempSubPages);
      setShowSubPage({
        subPages: sortedSubPages,
        templateId: sortedSubPages[0]?.template_id,
        subPageName: sortedSubPages[0]?.display_name,
        subPageId: sortedSubPages[0]?.id,
      });
    }
  };

  const getPages = async () => {
    setToken(token);
    const res = await getPagesApi();
    if (res && res.success === true) {
      handleShowSubPage(res.data);
      dispatch(updateAllPages(res.data));
    }
  };

  const getAllTemplates = async () => {
    setToken(token);
    const res = await getTemplatesApi();
    if (res && res.success === true) {
      dispatch(updateAllTemplates(res.data));
    }
  };

  const getProductCategoriesById = async () => {
    setToken(orderToken);
    const res = await getProductsCategoriesIdApi();
    if (res && res.success === true) {
      dispatch(addProductCategories(res.data));
    } else {
      displayErrorToast(res.message);
    }
  };

  const getPhasesData = async () => {
    setToken(token);
    const res = await getPhasesApi();

    if (res && res.success === true) {
      dispatch(getUpdatePhases(res.data));
    }
  };

  const callProductPagesAPIs = async () => {
    setLoader(true);
    if (isEmpty(allSubPagesConfig)) {
      await getAllSubPagesConfig();
    }
    if (isEmpty(allPages)) {
      await getPages();
    } else {
      handleShowSubPage(allPages);
    }

    if (isEmpty(allTemplates)) {
      await getAllTemplates();
    }
    if (isEmpty(productCategories)) {
      await getProductCategoriesById();
    }
    if (isEmpty(phasesList)) {
      await getPhasesData();
    }
  };

  useEffect(() => {
    callProductPagesAPIs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchFilterOrders = useCallback(
    (searchValue) => {
      const searchFilterOrders = Object.entries(categoryOrders).map(
        ([orderDate, orderData]) => {
          const filteredOrders = Object.entries(orderData.orders).filter(
            ([orderId, orderDetail]) => {
              return (
                (orderDetail?.customer &&
                  (orderDetail?.customer?.first_name
                    .trim()
                    .toLowerCase()
                    .includes(searchValue) ||
                    orderDetail?.customer?.last_name
                      .trim()
                      .toLowerCase()
                      .includes(searchValue) ||
                    (
                      orderDetail?.customer?.first_name +
                      " " +
                      orderDetail?.customer?.last_name
                    )
                      .trim()
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(searchValue))) ||
                (orderDetail?.call_name &&
                  orderDetail?.call_name
                    .trim()
                    .toLowerCase()
                    .replace(/ /g, "")
                    .includes(searchValue)) ||
                (orderDetail?.local_id &&
                  orderDetail?.local_id
                    .trim()
                    .toLowerCase()
                    .includes(searchValue)) ||
                (orderDetail?.id &&
                  orderDetail?.id
                    .toString()
                    .trim()
                    .toLowerCase()
                    .includes(searchValue)) ||
                (orderDetail?.items &&
                  orderDetail?.items.some((item) =>
                    item.product.name
                      .trim()
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(searchValue)
                  )) ||
                orderDetail?.items.some((item) =>
                  item.modifieritems.some((ele) =>
                    ele.modifier.name
                      .trim()
                      .toLowerCase()
                      .replace(/ /g, "")
                      .includes(searchValue)
                  )
                )
              );
            }
          );
          return [
            orderDate,
            {
              ...orderData,
              orders: Object.fromEntries(filteredOrders),
            },
          ];
        }
      );
      return Object.fromEntries(searchFilterOrders);
    },
    [categoryOrders]
  );

  const getBoxedAndCompletedFilter = useCallback(
    (orderDetail, key) => {
      return orderDetail?.items.some((item) => {
        if (item?.cake_items) {
          return item.cake_items.some(
            (cakeItem) =>
              itemInfo[cakeItem.id] &&
              itemInfo[cakeItem.id]?.item_phase >= phasesId[key]
          );
        } else {
          return (
            itemInfo[item.id] && itemInfo[item.id]?.item_phase >= phasesId[key]
          );
        }
      });
    },
    [itemInfo]
  );

  const getFilteredOrders = useCallback(
    (orders) => {
      const filterKeys = Object.keys(currentFilters);
      const filteredOrders = orders.filter(([orderId, orderDetail]) => {
        return filterKeys.every((key) => {
          switch (currentFilters[key]) {
            case 0:
              return true;
            case 1:
              return getBoxedAndCompletedFilter(orderDetail, key);
            case 2:
              return !getBoxedAndCompletedFilter(orderDetail, key);
            default:
              return true;
          }
        });
      });
      return filteredOrders;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilters]
  );

  const handleCheckboxfilterOrders = useCallback(
    (searchedOrders) => {
      if (!isEmpty(searchedOrders)) {
        const filteredOrders = getCheckboxFilteredOrder(
          searchedOrders,
          getFilteredOrders
        );
        return filteredOrders;
      }
      return searchedOrders;
    },
    [getFilteredOrders]
  );

  const searchedOrders = useMemo(() => {
    if (!isEmpty(categoryOrders)) {
      return handleSearchFilterOrders(searchText);
    } else {
      return {};
    }
  }, [searchText, categoryOrders, handleSearchFilterOrders]);

  const checkBoxFilterOrders = useMemo(
    () => handleCheckboxfilterOrders(searchedOrders),
    [searchedOrders, handleCheckboxfilterOrders]
  );

  const objectsToArray = () => {
    const ordersArray = [];
    if (checkBoxFilterOrders) {
      Object.entries(checkBoxFilterOrders).forEach(([orderDate, orderData]) => {
        Object.entries(orderData?.orders).forEach(([orderId, orderDetail]) => {
          ordersArray.push(orderDetail);
        });
      });
    }

    return ordersArray;
  };

  const handleUpdateItems = (items, updatedItemInfo, item_id) => {
    const newItems = items.map((item) => {
      if (item.cake_items && !isEmpty(item.cake_items)) {
        const cakeItems = item.cake_items.map((cake_item) => {
          if (cake_item?.id === item_id) {
            return {
              ...cake_item,
              ...updatedItemInfo,
            };
          }
          return {
            ...cake_item,
          };
        });

        return {
          ...item,
          cake_items: cakeItems,
        };
      } else {
        if (item?.id === item_id) {
          return {
            ...item,
            ...updatedItemInfo,
          };
        }
        return {
          ...item,
        };
      }
    });
    return newItems;
  };

  const handleUpdateCategoryOrder = (updatedItemInfo, itemId) => {
    const updateCategoryOrder = Object.entries(categoryOrders).map(
      ([orderDate, orderData]) => {
        let updatedOrders = [];
        if (orderData?.orders) {
          updatedOrders = Object.entries(orderData?.orders).map(
            ([orderId, orderDetail]) => {
              return [
                orderId,
                {
                  ...orderDetail,
                  items: handleUpdateItems(
                    orderDetail?.items,
                    updatedItemInfo,
                    itemId
                  ),
                },
              ];
            }
          );
        }
        return [
          orderDate,
          { ...orderData, orders: Object.fromEntries(updatedOrders) },
        ];
      }
    );

    let tempItemInfo = { ...itemInfo };

    tempItemInfo = {
      ...itemInfo,
      [itemId]: updatedItemInfo,
    };
    setItemInfo(tempItemInfo);
    setCategoryOrders(Object.fromEntries(updateCategoryOrder));
  };

  const renderSubPage = (onHandleSave) => {
    const template = allTemplates.find(
      (item) => item.id === showSubpage.templateId
    )?.template;

    const subPageConfig = allSubPagesConfig.filter(
      (pageConfig) =>
        parseInt(pageConfig?.page_id) === parseInt(showSubpage?.subPageId)
    );
    switch (template?.replace(".jsx", "")) {
      case "cupcake_template_1":
        return (
          <Orders
            handleUpdateCategoryOrder={handleUpdateCategoryOrder}
            ordersByDate={checkBoxFilterOrders}
            itemInfo={itemInfo}
            setItemInfo={setItemInfo}
            phasesList={phasesList}
            selectedPageID={showSubpage?.subPageId}
            setOrdersInfo={setOrdersInfo}
            ordersInfo={ordersInfo}
          />
        );

      case "cupcake_template_145":
        return (
          <CatGrid
            calenderDates={calenderDates}
            onSave={onHandleSave}
            setCalenderDates={setCalenderDates}
            orders={checkBoxFilterOrders}
            currentTemplateId={showSubpage.templateId}
            subpagesData={showSubpage.subPages}
            allSubPagesConfig={allSubPagesConfig}
            selectedPageID={showSubpage?.subPageId}
          />
        );

      case "cupcake_template_2":
        return (
          <Hourly
            hourlySubPageConfig={subPageConfig}
            orders={checkBoxFilterOrders}
            ordersInfo={ordersInfo}
          />
        );

      case "baker_template_1":
        return (
          <BakerList
            orders={objectsToArray()}
            bakerSubPageConfig={subPageConfig}
            ordersInfo={ordersInfo}
            itemInfo={itemInfo}
            setItemInfo={setItemInfo}
          />
        );
      case "production_template_1":
        return (
          <Production
            ordersByDate={searchedOrders}
            itemInfo={itemInfo}
            setItemInfo={setItemInfo}
            productionFilters={productionFilters}
            setFilterDropdown={setFilterDropdown}
            currentdropDownValue={currentdropDownValue}
          />
        );

      default:
        return;
    }
  };

  const handleRenderingFilterModals = () => {
    if (
      !(
        showSubpage.templateId ===
        templateArr.find((item) => item.templateName === "Cat-Grid.jsx").id
      )
    ) {
      if (
        showSubpage.templateId ===
        templateArr.find((item) => item.templateName === "Production.jsx").id
      ) {
        return (
          <ProductionFilterModal
            show={filterModalShow}
            onHide={() => setFilterModalShow(false)}
            {...{
              productionFilters,
              setProductionFilters,
              filterDropdown,
              setCurrentDropDownValue,
              currentdropDownValue,
            }}
          />
        );
      } else if (
        showSubpage.templateId ===
        templateArr.find((item) => item.templateName === "Baker-List.jsx").id
      ) {
        return (
          <BakerFilterModal
            show={filterModalShow}
            onHide={() => setFilterModalShow(false)}
            {...{ bakerFilter, setBakerFilter }}
          />
        );
      } else {
        return (
          <FilterModal
            show={filterModalShow}
            onHide={() => setFilterModalShow(false)}
            {...{ currentFilters, setCurrentFilters }}
          />
        );
      }
    }
  };

  useEffect(() => {
    if (showSubpage?.subPageId) {
      setPublicNotesLoader(true);
      getSubPageDiningConfigByID(showSubpage.subPageId);
    }
  }, [showSubpage.subPageId]);

  const renderNotes = () => {
    return publicNotesLoader ? (
      <CakeSpinner className="my-5" />
    ) : (
      subPagePublicNotes && (
        <section className="mt-5">
          <h2 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2">
            Notes
          </h2>
          <div>{subPagePublicNotes}</div>
        </section>
      )
    );
  };

  return (
    <OrderLayout
      {...{
        loader,
        showSubpage,
        calenderDates,
        setShowSubPage,
        setCalenderDates,
        setFilterModalShow,
        handleRenderingFilterModals,
        ordersInfo,
        itemInfo,
        categoryOrders,
      }}
    >
      {(onHandleSave) => (
        <div className="mb-3">
          {!(
            showSubpage.templateId ===
            templateArr.find((item) => item.templateName === "Cat-Grid.jsx").id
          ) && (
            <SearchInput
              onChangeHandler={(e) => {
                const searchValue = e.target.value
                  .trim()
                  .toLowerCase()
                  .replace(/ /g, "");
                setSearchText(searchValue);
              }}
              removeSearch={() => {
                setSearchText("");
              }}
              searchText={searchText}
            />
          )}
          <h6 className="text-primary h3 border-bottom-primary borde-primary font-weight-bold mb-3 pb-2">
            {productCatName} : {showSubpage?.subPageName}
          </h6>
          {renderSubPage(onHandleSave)}
          {renderNotes()}
        </div>
      )}
    </OrderLayout>
  );
};

export default ProductPage;
