import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setToken } from "../api";
import { getAllHomeConfigPagesApi } from "../api/configHome";
import { getPagesApi } from "../api/pages";
import CakeSpinner from "../components/common/CakeSpinner";
import Layout from "../components/common/layout";
import { updateAllPages, updateHomePage } from "../redux/actions/pageActions";
import { adminPages, reportPages } from "../utils/HomePageData";

const Home = () => {
  const { allPages } = useSelector((state) => state.pages);

  const { token } = useSelector((state) => state.user);
  const { homePages } = useSelector((state) => state.pages);
  const [loader, setLoader] = useState(false);
  const [homePageLoader, setHomePageLoader] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const getAllHomeConfigPages = async () => {
    setToken(token);
    setLoader(true);
    setHomePageLoader(true);
    const res = await getAllHomeConfigPagesApi();
    if (res && res.success === true) {
      dispatch(updateHomePage(res.data));
    }
    setLoader(false);
    setHomePageLoader(false);
  };
  useEffect(() => {
    if (isEmpty(homePages)) {
      getAllHomeConfigPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homePages]);

  const handleParams = (page, homePage) => {
    const selctedPage = allPages.find((allPage) => allPage.id === page.page_id);

    var parent = {};
    var child = {};

    if (selctedPage.sub_page) {
      parent = allPages.find((page) => page.id === selctedPage.sub_page);
      child = selctedPage;
    } else {
      const childs = allPages.filter(
        (page) => page.sub_page === selctedPage.id
      );
      child = childs[0];
      parent = selctedPage;
    }
    function convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    }

    const url =
      homePage.display_name !== "CUSTOMER SERVICES"
        ? `/${convertToSlug(parent?.display_name)}/${convertToSlug(
            child.display_name
          )}/`
        : "/order-overview";

    navigate(`${url}${location?.search}`);
  };

  const callProductPagesAPIs = async () => {
    setLoader(true);
    if (isEmpty(allPages)) {
      await getPages();
    }
    setLoader(false);
  };

  useEffect(() => {
    callProductPagesAPIs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPages = async () => {
    setToken(token);
    const res = await getPagesApi();
    if (res && res.success === true) {
      dispatch(updateAllPages(res.data));
    }
  };

  return (
    <Layout {...{ loader }}>
      <Container className="px-3">
        <Row className="justify-content-center">
          <Col xl={8} lg={10}>
            <h1 className="text-primary h2 mb-3 font-weight-bold">HOME</h1>
            {
              <>
                <h6 className="text-primary h4 border-bottom-primary borde-primary text-center font-weight-bold mb-3 pb-2">
                  Admin
                </h6>
                <Row>
                  {adminPages &&
                    adminPages.map((page, j) => (
                      <Col key={j} md={4} className="col-6">
                        <Link to={page.route}>
                          <Button
                            variant="primary"
                            className="mb-3  border-radius-10  p-3 w-100"
                          >
                            {page.pagename}
                          </Button>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </>
            }
            {
              <Row>
                {homePageLoader ? (
                  <CakeSpinner className="my-4" />
                ) : (
                  homePages &&
                  homePages.length > 0 &&
                  homePages.map((homePage, index) => {
                    return (
                      <React.Fragment key={index}>
                        {homePage?.children && homePage?.children.length > 0 && (
                          <>
                            <h6 className="text-primary h4 border-bottom-primary borde-primary text-center font-weight-bold mb-3 pb-2">
                              {homePage?.display_name}
                            </h6>
                            {homePage?.children &&
                              homePage?.children.length > 0 &&
                              homePage?.children.map((page) => (
                                <Col key={page.id} md={4} className="col-6">
                                  <Button
                                    variant="primary"
                                    className="mb-3  border-radius-10  p-3 w-100"
                                    onClick={() => handleParams(page, homePage)}
                                  >
                                    {page?.display_name}
                                  </Button>
                                </Col>
                              ))}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })
                )}
              </Row>
            }
            {
              <>
                <h6 className="text-primary h4 border-bottom-primary borde-primary text-center font-weight-bold mb-3 pb-2">
                  Reports
                </h6>
                <Row>
                  {reportPages &&
                    reportPages.map((page, j) => (
                      <Col key={j} md={4} className="col-6">
                        <Link to={page.route}>
                          <Button
                            variant="primary"
                            className="mb-3  border-radius-10  p-3 w-100"
                          >
                            {page.pagename}
                          </Button>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </>
            }
            {/* {
              <>
                <h6 className="text-primary h4 border-bottom-primary borde-primary text-center font-weight-bold mb-3 pb-2">
                  Customer Service
                </h6>
                <Row>
                  {customerServicePage &&
                    customerServicePage.map((page, j) => (
                      <Col key={j} md={4} className="col-6">
                        <Link to={page.route}>
                          <Button
                            variant="primary"
                            className="mb-3  border-radius-10  p-3 w-100"
                          >
                            {page.pagename}
                          </Button>
                        </Link>
                      </Col>
                    ))}
                </Row>
              </>
            } */}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
