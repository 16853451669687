export const multiplierListData = [
  { id: 1, display_name: "Filling", name: "ck_filling_area" },
  { id: 2, display_name: "Cake Serving", name: "ck_servings" },
  { id: 3, display_name: "Total Surface Area", name: "ck_surface_area" },
  { id: 4, display_name: "Cake Piece", name: "ck_piece_cnt" },
  { id: 5, display_name: "Bake Batch", name: "ck_batch" },
];

export const setting = {
  multiplier_ID: 1,
  printer_setting_ID: 2,
  board_sizes_ID: 3,
};

export const CONFIG_ID = {
  config_page: 3,
};

export const dinningTypeData = {
  toGo: "To Go",
  delivery: "Delivery",
  catering: "Catering",
  delivPart: "Deliv. Part",
  online: "Online",
  other: "Other",
  ship: "Ship",
};

export const orderTypeData = {
  Ord: "Ord.",
  Inv: "Inv.",
  order: "Order",
  invoice: "Invoice",
};

export const orderOverViewComponentData = {
  fulFill: "fulFill",
  order: "order",
  communication: "communication",
  details: "details",
  log: "log",
};

export const orderOverViewFilterData = {
  all: "All",
  pickups: "Pickups",
  localDeliveries: "Local-Deliveries",
  largeEvent: "Large Event",
  deliveryPartners: "Delivery-Partners",
  shipping: "Shipping",
  activeOrders: "Active-Orders",
  fullfilled: "Fullfilled",
  checkedIn: "Checked-In",
};

export const orderToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhbGciOiJIUzI1NiIsInN1YiI6MiwiaWF0IjoxNjUxNjIxNzQyfQ.kpfhHZiaLVC_xclHfivC4uvpYccS4inK4uKYUDS8Fvw";

export const print_key = "philip@pattys-cakes.com_bf3ba31934ec4455";

export const dinningOptionData = [
  { id: 1, display_name: "Eat in", name: "eat_in" },
  { id: 2, display_name: "Delivery", name: "delivery" },
  { id: 3, display_name: "Catering", name: "catering" },
  { id: 4, display_name: "Deliv. Part.", name: "deliv_part" },
  { id: 5, display_name: "Online", name: "online" },
  { id: 6, display_name: "Other", name: "other" },
  { id: 7, display_name: "Ship", name: "ship" },
  { id: 8, display_name: "Drive-through", name: "drive_thru" },
  { id: 0, display_name: "To Go", name: "to_go" },
];

export const modifierCatData = {
  flavor: [8, 9],
  filling: 10,
  iced_color: 101,
  iced: 38,
  borders: 37,
  build: 97,
  board: "board",
};

export const modifierIdData = {
  white_iced_color: 1014,
  vegan_iced: [1304, 1305, 1306, 1307, 1308, 1309, 1310],
};

export const bakersLevels = {
  nonTiered: "Non-Tiered",
  tiered: "Tiered",
};

export const print = {
  printer_setting: 8,
};

export const phasesId = {
  completed: 90,
  boxed: 100,
  baked: 20,
  cakeAssembled: 60,
};

export const tiersData = {
  tier_one: 1,
  tier_two: 2,
};

export const pageId = {
  standardCakes: 31,
  decorator: 39,
};

export const filterData = {
  all: 0,
  selected: 1,
  notSelected: 2,
  partiallySelected: 3,
};
