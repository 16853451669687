import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "react-router-dom";
import CakeSpinner from "./CakeSpinner";
import OrderOverviewDetailHeader from "./OrderOverviewDetailHeader";

const Layout = ({
  children,
  loader,
  ordersInfo,
  itemInfo,
  categoryOrders,
  ...rest
}) => {
  const location = useLocation();
  const { pathname } = location;
  const pathArray = pathname.split("/");
  const isOrderOverviewCategory = pathArray.includes("order-overview-category");
  return (
    <>
      <Header isLoggedIn={true} {...rest} />
      {isOrderOverviewCategory && <OrderOverviewDetailHeader />}
      <main className="">{loader ? <CakeSpinner /> : children}</main>
      <Footer
        isLoggedIn={true}
        ordersInfo={ordersInfo}
        itemInfo={itemInfo}
        categoryOrders={categoryOrders}
      />
    </>
  );
};
export default Layout;
