import { isEmpty } from "lodash";
import moment from "moment";
import { dinningTypeData } from "../utils/StaticData";

export const dateToDDMMYYYY = (date) => {
  var dateStart = new Date(date);
  if (dateStart instanceof Date && !isNaN(dateStart)) {
    return `${("0" + dateStart.getDate()).slice(-2)}/${(
      "0" +
      (dateStart.getMonth() + 1)
    ).slice(-2)}/${dateStart.getFullYear()}`;
  } else {
    return "";
  }
};

export const dateToHHMM = (date) => {
  var dateStart = new Date(date);
  if (dateStart instanceof Date && !isNaN(dateStart)) {
    return `${dateStart.toLocaleTimeString("en-US").slice(0, -6)} ${dateStart
      .toLocaleTimeString("en-US")
      .slice(-2)}`;
  } else {
    return "";
  }
};

export const capitalize = (input) => {
  return input
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const getDiffOfDate = (strDate, endDate) => {
  const a = moment(strDate);
  const b = moment(endDate);
  return b.diff(a, "days");
};

export const checkNoOrdersHandler = (orders) =>
  Object.entries(orders).filter(
    ([orderDate, orderData]) => !isEmpty(orderData.orders)
  );

export const getDinningType = (dinningType) => {
  const { toGo, delivery, catering, delivPart, online, other, ship } =
    dinningTypeData;
  switch (dinningType) {
    case 1:
      return toGo;
    case 2:
      return delivery;
    case 3:
      return catering;
    case 4:
      return delivPart;
    case 5:
      return online;
    case 6:
      return other;
    case 7:
      return ship;
    case 0:
      return toGo;
    default:
      return;
  }
};

export const getBalDueClassName = (BalDue) => {
  if (BalDue === 0) return "text-success";
  else if (BalDue > 0) return "bg-danger text-white text-center p-0 rounded";
  else if (BalDue < 0)
    return "bg-in-progress text-white text-center p-0 rounded";
  else return "";
};

export const calulateUntillTime = (pickupTime) => {
  const a = moment(pickupTime);
  const b = moment();
  return a.diff(b, "minutes");
};

export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.3)"
    );
  }
  throw new Error("Bad Hex");
};

export const calculatePercentage = (orders, getDoneItemsAndTotalItems) => {
  if (!isEmpty(orders)) {
    const [totalDone, totalItems] = getDoneItemsAndTotalItems(orders);
    const percentage = (totalDone / totalItems) * 100;
    return percentage;
  }
  return 0;
};

export const getCheckboxFilteredOrder = (orders, filterFunc) => {
  const filteredSearchedOrders = Object.entries(orders).map(
    ([orderDate, orderData]) => {
      let filteredOrders = [];
      if (orderData?.orders) {
        filteredOrders = filterFunc(Object.entries(orderData?.orders));
        return [
          orderDate,
          {
            ...orderData,
            orders: Object.fromEntries(filteredOrders),
          },
        ];
      }
      return [
        orderDate,
        {
          ...orderData,
        },
      ];
    }
  );

  return Object.fromEntries(filteredSearchedOrders);
};

export const removeDuplicate = (arr, key) => [
  ...new Map(arr.map((v) => [v[key], v])).values(),
];
