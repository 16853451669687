import { isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setToken } from "../../api";
import { editItemInfoApi, editMulItemInfoApi } from "../../api/iteminfo";
import { getSingleOrderInfoApi } from "../../api/orders";
import ListIcon from "../../assets/icons/list.svg";
import subDirectorySVG from "../../assets/icons/sub-directory.svg";
import { displaySuccessToast } from "../../global/displayToast";
import useOrderOverviewModal from "../../hooks/useOrderOverviewModal";
import {
  orderOverViewComponentData,
  orderToken,
  pageId,
  phasesId,
  tiersData,
} from "../../utils/StaticData";
import NoData from "../common/NoData";
import SortButton from "../common/SortButton";
import OrderOverviewModal from "../modals/OrderOverviewModal";

const displayTimeFormat = "ddd MMM. D - hh:mm a";
const displayHeaderDateFormat = "dddd, MMMM D";

const sortButtonsData = [
  { name: "customerName", label: "Name" },
  { name: "order_time", label: "Time" },
  { name: "orderLocalId", label: "Order #" },
];

const Orders = ({
  ordersByDate,
  handleUpdateCategoryOrder,
  selectedPageID,
  itemInfo,
  setItemInfo,
  setOrdersInfo,
  ordersInfo,
}) => {
  const [displayOrdersByDate, setDisplayOrdersByDate] = useState([]);
  const [isToDisplayAllOrders, setIsToDisplayAllOrders] = useState(false);
  const [activeSortingField, setActiveSortingField] = useState("order_time");
  const [sortOrder, setSortOrder] = useState(false);
  const { allPages } = useSelector((state) => state.pages);

  const [
    handleOrderOverviewModal,
    orderOverviewModalShow,
    componentType,
    handleCloseModal,
    handleChangeOrderInfo,
  ] = useOrderOverviewModal(ordersInfo);

  useEffect(() => {
    if (ordersByDate) {
      const updatedOrders = Object.entries(ordersByDate).map(
        ([OrderDate, orderData]) => {
          const ordersArraybyData = [];
          Object.entries(orderData?.orders).forEach(
            ([orderId, orderDetails]) => {
              ordersArraybyData.push(orderDetails);
            }
          );
          return [OrderDate, ordersArraybyData];
        }
      );
      const removedEmptyOrders = updatedOrders.filter(
        ([orderDate, orderData]) => !isEmpty(orderData)
      );
      if (updatedOrders) {
        setDisplayOrdersByDate(removedEmptyOrders);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersByDate]);

  const handleSort = useCallback((allOrders, sortField, sortOrder) => {
    const sortedList = [...allOrders].sort((a, b) => {
      if (sortField === "customerName") {
        const a_fullName = a.customer.first_name + a.customer.last_name;
        const b_fullName = b.customer.first_name + b.customer.last_name;
        if (a_fullName === undefined || null) return 1;
        if (b_fullName === undefined || null) return -1;
        if (
          a_fullName === undefined ||
          (null && b_fullName === undefined) ||
          null
        )
          return 0;

        return (
          a_fullName.toString().localeCompare(b_fullName.toString(), "en", {
            numeric: true,
          }) * (sortOrder ? 1 : -1)
        );
      } else if (sortField === "orderLocalId") {
        const a_localId = parseInt(a.local_id.replace(/^w/, ""));
        const b_localId = parseInt(b.local_id.replace(/^w/, ""));
        return (a_localId - b_localId) * (sortOrder ? 1 : -1);
      }
      return 0;
    });
    return sortedList;
  }, []);

  const getDataSortedByTime = useCallback((orderData, sortTimeOrder) => {
    const timeSorted = orderData.sort((a, b) => {
      const a_orderTimeMoment = moment(a?.pickup_time).valueOf();
      const b_orderTimeMoment = moment(b?.pickup_time).valueOf();
      return (a_orderTimeMoment - b_orderTimeMoment) * (sortTimeOrder ? 1 : -1);
    });
    return timeSorted;
  }, []);

  const handleTimeSort = useCallback(
    (orderData, sortOrder) => {
      setIsToDisplayAllOrders(false);
      if (orderData) {
        const sortedOrder = orderData.map(([dateSlot, orderDataArry]) => {
          const sortedTimeData = getDataSortedByTime(orderDataArry, sortOrder);
          return [dateSlot, sortedTimeData];
        });
        return sortedOrder;
      }
    },
    [getDataSortedByTime]
  );

  const handleCompletedAPI = async (e, itemId, item) => {
    const payload = {
      completed_phases: {
        [tiersData.tier_one]: {
          [phasesId.completed]: e.target.checked,
        },
        ...(item?.product?.app_info?.ck_build === "dl" && {
          [tiersData.tier_two]: {
            [phasesId.completed]: e.target.checked,
          },
        }),
      },
    };
    setToken(orderToken);
    const res = await editItemInfoApi(payload, itemId);
    if (res && res.success === true) {
      handleUpdateCategoryOrder(res.data, itemId);
      displaySuccessToast(res.message);
      return res;
    }
  };

  const getOrderInfoById = async (orderId, date) => {
    const res = await getSingleOrderInfoApi(+orderId);

    if (res && res.success) {
      setOrdersInfo((prev) => {
        return {
          ...prev,
          [date]: {
            orders: {
              ...prev?.[date].orders,
              [orderId]: res?.data,
            },
          },
        };
      });
    }
  };

  const handleCompletedChange = async (
    e,
    itemId,
    item,
    orderId,
    pickup_time
  ) => {
    // UPDATE displayOrdersByDate state

    const date = moment(pickup_time).format("YYYY-MM-DD");
    setItemInfo((prev) => {
      return {
        ...prev,
        [itemId]: {
          item_phase: e.target.checked ? phasesId.completed : null,
        },
      };
    });

    // API CALL
    const res = await handleCompletedAPI(e, itemId, item);
    const updatedItemInfo = {
      ...itemInfo,
      [itemId]: res.data,
    };

    setItemInfo(updatedItemInfo);

    await getOrderInfoById(orderId, date);
  };

  const handleOnClickSort = (btnName) => {
    setActiveSortingField(btnName);
    setSortOrder((prev) => !prev);
    if (btnName === "order_time") {
      setIsToDisplayAllOrders(false);
    } else {
      setIsToDisplayAllOrders(true);
    }
  };

  const getAllOrders = useCallback((sortedOrders) => {
    const ordersArray = [];

    sortedOrders.forEach(([orderDate, orderData]) => {
      orderData.forEach((orderDetail) => {
        ordersArray.push(orderDetail);
      });
    });

    return ordersArray;
  }, []);

  const updateItemInfo = (res, items) => {
    if (items.length === 1) {
      const updatedItemInfo = {
        ...itemInfo,
        [items[0]?.id]: res.data,
      };
      setItemInfo(updatedItemInfo);
    } else {
      const updatedItemInfo = {
        ...itemInfo,
        ...res.data,
      };
      setItemInfo(updatedItemInfo);
    }
  };

  const handleBoxedOnChange = async (e, items, orderId, pickup_time) => {
    const date = moment(pickup_time).format("YYYY-MM-DD");

    let updatedItemInfo = { ...itemInfo };
    const itemIdsArray = items.map((item) => item?.id);
    itemIdsArray.forEach((itemId) => {
      updatedItemInfo = {
        ...itemInfo,
        [itemId]: {
          ...itemInfo[itemId],
          item_phase: e.target.checked ? phasesId.boxed : null,
        },
      };
    });
    setItemInfo(updatedItemInfo);

    let idsForDl = [];
    let idsForSl = [];

    items.forEach((item) => {
      if (item?.product?.app_info?.ck_build === "dl") {
        idsForDl.push(item?.id);
      } else {
        idsForSl.push(item?.id);
      }
    });

    const payloadForSl = {
      ids: idsForSl,
      completed_phases: {
        [tiersData.tier_one]: {
          [phasesId.boxed]: e.target.checked,
        },
      },
    };
    const payloadForDl = {
      ids: idsForDl,
      completed_phases: {
        [tiersData.tier_one]: {
          [phasesId.boxed]: e.target.checked,
        },
        [tiersData.tier_two]: {
          [phasesId.boxed]: e.target.checked,
        },
      },
    };

    if (!isEmpty(idsForSl)) {
      const res = await editMulItemInfoApi(payloadForSl);
      updateItemInfo(res, items);
    }

    if (!isEmpty(idsForDl)) {
      const res = await editMulItemInfoApi(payloadForDl);
      updateItemInfo(res, items);
    }

    await getOrderInfoById(orderId, date);
  };

  const handleDisableCompleted = (item) => {
    const data = allPages.find((item) => item.id === selectedPageID);
    if (
      (!data?.phase_in_id || !itemInfo[item?.id]?.item_phase) &&
      data?.id !== pageId.decorator
    ) {
      return false;
    }
    if (itemInfo[item?.id]?.item_phase >= data?.phase_in_id) {
      return false;
    }
    return true;
  };

  const handleDisableBox = (items) => {
    let itemIds = [];
    items.forEach((item) => {
      if (item?.cake_items) {
        itemIds = [
          ...itemIds,
          ...item?.cake_items.map((cakeItem) => cakeItem.id),
        ];
      } else {
        itemIds.push(item?.id);
      }
    });

    const isDisabled = itemIds.every((itemId) => {
      const check =
        itemInfo[itemId] && itemInfo[itemId]?.item_phase >= phasesId.completed;
      return check;
    });
    return isDisabled;
  };

  const handleBoxedCheck = (items) => {
    const itemIds = items.map((item) => item.id);
    const boxCheck = itemIds.every(
      (item) => itemInfo[item]?.item_phase === phasesId.boxed
    );
    return boxCheck;
  };

  const handleToast = (item) => {
    const checkDisabled = handleDisableCompleted(item);
    if (checkDisabled) {
      // displayErrorToast("Fill in cake Assembly first");
    }
  };

  const renderItems = (
    item,
    index,
    self,
    orderID,
    pickup_time,
    isDecorator
  ) => (
    <React.Fragment key={item.id + index}>
      <tr>
        {isDecorator ? <td></td> : <td>{item?.product?.categories_text}</td>}
        <td className="ps-2">
          {item?.quantity} X {item?.product?.name}
        </td>
        <td>
          <span onClick={() => handleToast(item)}>
            <Form.Group>
              <Form.Check
                disabled={handleDisableCompleted(item)}
                onChange={(e) =>
                  handleCompletedChange(e, item.id, item, orderID, pickup_time)
                }
                label=""
                id={`complete-${item.id}`}
                checked={itemInfo[item.id]?.item_phase >= phasesId.completed}
                type="checkbox"
                className={`custom-input-box checkbox-24 ${
                  handleDisableCompleted(item) && "cursorNotAllowed"
                }`}
                name={"item1"}
              />
            </Form.Group>
          </span>
        </td>
        {selectedPageID === pageId.standardCakes && (
          <td>
            <Form.Group>
              <Form.Check
                disabled={!handleDisableBox([item])}
                onChange={(e) =>
                  handleBoxedOnChange(e, [item], orderID, pickup_time)
                }
                label=""
                id={`standardCakesboxed-${item?.id}`}
                type="checkbox"
                className={`custom-input-box checkbox-24 ${
                  !handleDisableBox([item]) && "cursorNotAllowed"
                }`}
                checked={handleBoxedCheck([item])}
                name={"standardCakes-box"}
              />
            </Form.Group>
          </td>
        )}
      </tr>
      {item?.modifieritems &&
        item?.modifieritems.map((modifierItem) => {
          return [...Array(modifierItem?.qty).keys()].map((value) => (
            <tr key={`${modifierItem.id}-${value}`}>
              <td></td>
              <td className="ps-2">
                <img
                  src={subDirectorySVG}
                  className="p-1"
                  height="30"
                  width="30"
                  alt=""
                />
                {modifierItem?.modifier?.name}
              </td>
            </tr>
          ));
        })}
      {self.length > 1 && (
        <tr>
          <td></td>
          <td>
            <hr />
          </td>
          <td>
            <hr />
          </td>
        </tr>
      )}
    </React.Fragment>
  );

  const renderOrders = (ordersArray) => {
    return ordersArray.map((orderDetail) => (
      <tr key={orderDetail.id}>
        <td>
          <div className="d-flex gap-2">
            <div
              onClick={() =>
                handleOrderOverviewModal(
                  orderOverViewComponentData?.order,
                  orderDetail
                )
              }
              style={{ cursor: "pointer" }}
            >
              <img src={ListIcon} alt="list" height={22} />
            </div>
            <div>
              {orderDetail?.customer?.first_name +
                " " +
                orderDetail?.customer?.last_name}
            </div>
          </div>
        </td>
        <td>{moment(orderDetail?.pickup_time).format(displayTimeFormat)}</td>
        <td>{orderDetail?.local_id}</td>
        <td>
          <table className="w-100 table-fixed">
            <colgroup>
              <col style={{ minWidth: "150px" }} />
              <col style={{ minWidth: "150px" }} />
              <col width={100} />
              {selectedPageID === pageId.standardCakes && <col width={100} />}
            </colgroup>
            <tbody>
              {orderDetail?.items.map((item, index, self) =>
                item?.cake_items ? (
                  selectedPageID === pageId.decorator ? (
                    <React.Fragment key={item.id + index}>
                      <tr>
                        <td>{item?.product?.categories_text}</td>
                        <td>
                          {item?.quantity} X {item?.product?.name}
                        </td>
                      </tr>
                      {item?.modifieritems &&
                        item?.modifieritems.map((modifierItem) => (
                          <tr key={modifierItem.id}>
                            <td></td>
                            <td>
                              <img
                                src={subDirectorySVG}
                                className="p-1"
                                height="30"
                                width="30"
                                alt=""
                              />
                              {modifierItem?.modifier?.name}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      {item?.cake_items.map((cakeItem, cakeIndex, cakeSelf) =>
                        renderItems(
                          cakeItem,
                          cakeIndex,
                          cakeSelf,
                          orderDetail.id,
                          orderDetail.pickup_time,
                          true
                        )
                      )}
                    </React.Fragment>
                  ) : (
                    item?.cake_items.map((cakeItem, cakeIndex, cakeSelf) =>
                      renderItems(
                        cakeItem,
                        cakeIndex,
                        cakeSelf,
                        orderDetail.id,
                        orderDetail.pickup_time,
                        false
                      )
                    )
                  )
                ) : (
                  renderItems(
                    item,
                    index,
                    self,
                    orderDetail.id,
                    orderDetail?.pickup_time,
                    false
                  )
                )
              )}
            </tbody>
          </table>
        </td>
        {selectedPageID !== pageId.standardCakes && (
          <td className="text-center">
            <Form.Group>
              <Form.Check
                disabled={!handleDisableBox(orderDetail?.items)}
                onChange={(e) =>
                  handleBoxedOnChange(
                    e,
                    orderDetail?.items,
                    orderDetail.id,
                    orderDetail?.pickup_time
                  )
                }
                label=""
                id={`boxed-${orderDetail.id}`}
                type="checkbox"
                className={`custom-input-box checkbox-24 ${
                  !handleDisableBox(orderDetail?.items) && "cursorNotAllowed"
                }`}
                checked={handleBoxedCheck(orderDetail?.items)}
                name={"order-box"}
              />
            </Form.Group>
          </td>
        )}
      </tr>
    ));
  };

  const sortedAllOrders = useMemo(() => {
    const allOrders = getAllOrders(displayOrdersByDate);
    if (activeSortingField === "order_time") {
      return handleTimeSort(displayOrdersByDate, sortOrder);
    } else {
      return handleSort(allOrders, activeSortingField, sortOrder);
    }
  }, [
    activeSortingField,
    sortOrder,
    displayOrdersByDate,
    getAllOrders,
    handleTimeSort,
    handleSort,
  ]);

  const selectedOrdersView = isToDisplayAllOrders
    ? sortedAllOrders
    : displayOrdersByDate;

  return (
    <Table responsive className="editable-table custom-table-striped">
      <colgroup>
        <col width={150} />
        <col width={180} />
        <col width={130} />
        {selectedPageID === pageId.standardCakes ? (
          <col width={850} />
        ) : (
          <col style={{ minWidth: "650px" }} />
        )}
        {selectedPageID !== pageId.standardCakes && <col width={120} />}
      </colgroup>
      <thead className="border-0 ">
        <tr>
          {sortButtonsData.map((data, index) => (
            <th key={index}>
              <SortButton
                activeSortingField={activeSortingField}
                btnName={data.name}
                onClickSort={handleOnClickSort}
                btnLabel={data.label}
              />
            </th>
          ))}

          <th>
            <table className="w-100 table-fixed">
              <colgroup>
                <col style={{ minWidth: "150px" }} />
                <col style={{ minWidth: "150px" }} />
                <col width={100} />
                {selectedPageID === pageId.standardCakes && <col width={100} />}
              </colgroup>
              <thead>
                <tr>
                  <th>Categories</th>
                  <th>Items</th>
                  <th>Completed</th>
                  {selectedPageID === pageId.standardCakes && <th>Boxed</th>}
                </tr>
              </thead>
            </table>
          </th>
          {selectedPageID !== pageId.standardCakes && (
            <th className="text-center">Boxed</th>
          )}
        </tr>
      </thead>
      <tbody>
        <OrderOverviewModal
          show={orderOverviewModalShow}
          onHide={handleCloseModal}
          componenttype={componentType}
          handleChangeOrderInfo={handleChangeOrderInfo}
        />

        {!isEmpty(selectedOrdersView) ? (
          !isToDisplayAllOrders ? (
            selectedOrdersView.map(([dateSlot, orderData]) => (
              <React.Fragment key={dateSlot}>
                <tr className="slot-header">
                  <th colSpan={5} className="ps-4">
                    {moment(dateSlot).format(displayHeaderDateFormat)}
                  </th>
                </tr>
                {renderOrders(orderData)}
              </React.Fragment>
            ))
          ) : (
            renderOrders(selectedOrdersView)
          )
        ) : (
          <tr className="bg-white">
            <td colSpan={5}>
              <NoData />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default Orders;
