import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Table,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getTextMessagesApi,
  addTextMessageslApi,
  editTextMessagesApi,
  getPrintSettingApi,
  getAppSettingApi,
  editAppSettingApi,
} from "../../api/settings";
import Layout from "../../components/common/layout";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../global/displayToast";
import editIcon from "../../assets/icons/pencil.svg";
import resetIcon from "../../assets/icons/reset.svg";
import { setToken } from "../../api";
import {
  updateBoardSizes,
  updateCurrentPrintSetting,
  updateSettingsMessage,
} from "../../redux/actions/settingsAction";
import { isEmpty } from "lodash";
import { setting } from "../../utils/StaticData";

const Settings = (props) => {
  const { token } = useSelector((state) => state.user);
  const { settingsMessageList, currentPrintSetting } = useSelector(
    (state) => state.settings
  );
  const [loader, setLoader] = useState(false);
  const [onSubmitLoader, setOnSubmitLoader] = useState(false);
  const [printSettingsOptions, setPrintSettingsOptions] = useState([]);
  const [checkJsonData, setCheckJsonData] = useState(false);
  const [jsonValue, setJsonValue] = useState("");
  const [printSelectionValue, setPrintSelectionValue] = useState("");
  const [printSelectionLoader, setPrintSelectionLoader] = useState(false);
  const [boardSizesLoader, setBoardSizesLoader] = useState(false);

  useEffect(() => {
    if (currentPrintSetting) {
      setPrintSelectionValue(currentPrintSetting?.id);
    }
  }, [currentPrintSetting]);

  const initialState = {
    title: "",
    content: "",
    sort: "",
    editId: "",
  };
  const [state, setState] = useState(initialState);

  const dispatch = useDispatch();

  const getSettingsData = async () => {
    const res = await getTextMessagesApi();

    if (res && res.success === true) {
      dispatch(updateSettingsMessage(res.data));
    }
  };

  useEffect(() => {
    if (!isEmpty(printSettingsOptions)) {
      getAppSetting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [printSettingsOptions]);

  const getPrintSettings = async () => {
    const res = await getPrintSettingApi();

    if (res && res.success === true) {
      setPrintSettingsOptions(res.data);
    }
  };

  const getAppSetting = async () => {
    const res = await getAppSettingApi();

    if (res && res.success === true) {
      const boardSizesData =
        res.data.find((val) => val?.id === setting.board_sizes_ID)?.settings ||
        {};
      dispatch(updateBoardSizes({ ...boardSizesData }));
      setJsonValue(JSON.stringify(boardSizesData, undefined, 4));

      if (!isEmpty(printSettingsOptions)) {
        const printerSetting =
          res.data.find((val) => val?.id === setting.printer_setting_ID)
            ?.settings?.id || "";
        const printData = printSettingsOptions.find(
          (val) => val.id === +printerSetting
        );
        dispatch(updateCurrentPrintSetting(printData));
      }
    }
  };
  const defaultUseEffect = async () => {
    setToken(token);
    setLoader(true);
    if (isEmpty(settingsMessageList)) await getSettingsData();
    await getPrintSettings();
    setLoader(false);
  };

  useEffect(() => {
    defaultUseEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (data) => {
    setState({
      title: data.title,
      content: data.content,
      sort: data.sort.toString(),
      editId: data.id,
    });
  };

  const handleSubmit = async () => {
    const { title, content, sort, editId } = state;
    if (title && content && sort) {
      const data = {
        title: title.trim(),
        content: content.trim(),
        sort,
      };
      setOnSubmitLoader(true);
      if (editId) {
        const res = await editTextMessagesApi(data, editId);
        if (res && res.success === true) {
          setState(initialState);
          const updatedSettings = settingsMessageList.map((message) => {
            if (message.id === res.data.id) return res.data;
            return message;
          });
          dispatch(updateSettingsMessage(updatedSettings));
          displaySuccessToast(res.message);
        }
      } else {
        const res = await addTextMessageslApi(data);
        if (res && res.success === true) {
          setState(initialState);
          const updatedSettings = [...settingsMessageList, res.data];
          dispatch(updateSettingsMessage(updatedSettings));
          displaySuccessToast(res.message);
        }
      }
    } else {
      displayErrorToast("Please fill all fields", 3000);
    }
    setOnSubmitLoader(false);
  };

  const handleCancel = () => {
    setState(initialState);
  };

  const handleSort = (levelList) =>
    levelList.sort((a, b) => {
      if (a.sort !== b.sort) return a.sort - b.sort;
      else
        return a.title
          .replace(/\s/g, "")
          .localeCompare(b.title.replace(/\s/g, ""));
    });

  const handleJsonValue = (text) => {
    setJsonValue(text);
    if (typeof text !== "string") {
      setCheckJsonData(false);
    }
    try {
      JSON.parse(text);
      setCheckJsonData(true);
    } catch (error) {
      setCheckJsonData(false);
    }
  };

  const editAppSetting = async (postData, id) => {
    const res = await editAppSettingApi(postData, id);
    if (res && res.success) {
      displaySuccessToast(res.message);
      if (id === setting.board_sizes_ID) {
        dispatch(updateBoardSizes(res.data.settings));
      } else if (id === setting.printer_setting_ID) {
        const printData = printSettingsOptions.find(
          (val) => val.id === +res?.data?.settings?.id
        );
        dispatch(updateCurrentPrintSetting(printData));
      }
    } else {
      displayErrorToast(res.message);
    }
    setPrintSelectionLoader(false);
    setBoardSizesLoader(false);
  };

  const handleJsonDataSubmit = async () => {
    const postData = { settings: JSON.parse(jsonValue) };
    setBoardSizesLoader(true);
    await editAppSetting(postData, setting.board_sizes_ID);
  };

  const handlePrinterSubmit = async () => {
    const postData = { settings: { id: printSelectionValue } };
    setPrintSelectionLoader(true);
    await editAppSetting(postData, setting.printer_setting_ID);
  };

  return (
    <Layout loader={loader}>
      <Container>
        <Row className="justify-content-left">
          <Col sm={12}>
            <div className="mb-3">
              {/* Text Messages */}
              <section>
                <h6 className="text-primary h3 border-bottom-primary borde-primary font-weight-bold mb-3 pb-2">
                  Settings
                </h6>
                <h6 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2">
                  Text Messages
                </h6>

                <Table responsive className="editable-table">
                  <colgroup>
                    <col width={140} />
                    <col width={300} />
                    <col width={60} />
                    <col width={60} />
                    <col width={80} />
                  </colgroup>
                  <thead className="border-0">
                    <tr>
                      <th>title</th>
                      <th>content</th>
                      <th>sort</th>
                      <th>id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingsMessageList &&
                      handleSort(settingsMessageList).map((text, i) => {
                        return (
                          <tr key={i}>
                            <td>{text.title}</td>
                            <td>{text.content}</td>
                            <td>{text.sort}</td>
                            <td>{text.id}</td>
                            <td
                              className="text-end"
                              onClick={() => handleEdit(text)}
                            >
                              <img
                                className="cursorPointer"
                                alt="edit"
                                src={editIcon}
                                width={30}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={state.title}
                            className="custom-input"
                            onChange={(e) =>
                              setState({
                                ...state,
                                title: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            rows={2}
                            value={state.content}
                            className="custom-input"
                            onChange={(e) =>
                              setState({
                                ...state,
                                content: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            value={state.sort}
                            className="custom-input"
                            onChange={(e) =>
                              setState({
                                ...state,
                                sort: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </td>
                      <td>{state.editId}</td>
                      <td>
                        <div className="d-flex justify-content-end">
                          <div className="text-end">
                            {/* Please make these below span as buttons such that disable funtionality we can provide */}
                            <Button
                              onClick={() => handleSubmit()}
                              className="p-2"
                            >
                              <div className="d-flex gap-2 align-items-center">
                                <span className={!onSubmitLoader ? "ms-2" : ""}>
                                  {state.editId ? "Update" : "Add"}
                                </span>
                                <span>
                                  {onSubmitLoader && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </div>
                            </Button>
                          </div>
                          <span
                            className="cursorPointer"
                            onClick={() => handleCancel()}
                          >
                            <img src={resetIcon} width={30} alt="reset" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </section>

              {/* Board Sizes */}
              <section>
                <h6 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2">
                  Board Sizes
                </h6>
                <p className="text-content-muted">
                  All data in here needs to be properly formatted and validated
                  in JSON format. Start and end bracket {}.
                </p>
                <div>
                  <textarea
                    className="w-100 p-2"
                    placeholder="{JSON}"
                    value={jsonValue || ""}
                    onChange={(e) => handleJsonValue(e.target.value)}
                  />
                  <div className="text-end">
                    <Button
                      disabled={!checkJsonData}
                      onClick={() => handleJsonDataSubmit()}
                      className="p-2"
                    >
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ maxHeight: "10px" }}
                      >
                        <span className={!boardSizesLoader ? "ms-2" : ""}>
                          Save
                        </span>
                        <span>
                          {boardSizesLoader && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </section>

              {/* Printer Selection */}
              <section>
                <h6 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2">
                  Printer Selection
                </h6>
                <p className="text-content-muted">
                  Choose which printer the orders should print to.
                </p>
                <div>
                  <Form.Select
                    value={printSelectionValue}
                    onChange={(e) => setPrintSelectionValue(e.target.value)}
                    className="custom-input mb-0 w-25"
                  >
                    <option value="" hidden>
                      Printer name (id)
                    </option>
                    <option value="">none</option>
                    {!isEmpty(printSettingsOptions) &&
                      printSettingsOptions.map((print, i) => {
                        return (
                          <option value={print.id} key={`dropdown-${print.id}`}>
                            {`${print?.name} - (${print.id})`}
                          </option>
                        );
                      })}
                  </Form.Select>
                  <div className="text-end">
                    <Button
                      disabled={isEmpty(printSelectionValue)}
                      onClick={() => handlePrinterSubmit()}
                      className="p-2"
                    >
                      <div
                        className="d-flex gap-2 align-items-center"
                        style={{ maxHeight: "10px" }}
                      >
                        <span className={!printSelectionLoader ? "ms-2" : ""}>
                          Save
                        </span>
                        <span>
                          {printSelectionLoader && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                    </Button>
                  </div>
                </div>
              </section>

              {/* Dev Mode */}
              <section>
                <h2 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2">
                  Dev Mode
                </h2>
                <p className="text-content-muted">
                  Dev mode will enable troubleshooting data to appear throughout
                  the site for admin's only.
                </p>
                <div className="d-flex">
                  <span className="text-content-muted">On</span>
                  <Form.Group>
                    <Form.Check
                      className="custom-switch  mx-2"
                      type="checkbox"
                      checked={state.active || ""}
                      id="d"
                      label=""
                      onChange={(e) =>
                        setState({
                          ...state,
                          active: e.target.checked,
                        })
                      }
                    />
                  </Form.Group>
                  <span className="text-content-muted">Off</span>
                </div>
              </section>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Settings;
