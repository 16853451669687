export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// Pages
export const UPDATE_HOME_PAGE_DATA = "UPDATE_HOME_PAGE_DATA";
export const UPDATE_ALL_PAGES = "UPDATE_ALL_PAGES";

// templates
export const UPDATE_TEMPLATES = "UPDATE_TEMPLATES";

// sub-page config
export const UPDATE_SUB_PAGE_CONFIG = "UPDATE_SUB_PAGE_CONFIG";

//orders

export const ADD_SINGLE_ORDER_DETAIL = "ADD_SINGLE_ORDER_DETAIL";
export const REMOVE_SINGLE_ORDER_DETAIL = "REMOVE_SINGLE_ORDER_DETAIL";
export const ADD_PRODUCT_CATEGORIES = "ADD_PRODUCT_CATEGORIES";
export const ADD_SINGLE_ORDER_INFO_DETAIL = "ADD_SINGLE_ORDER_INFO_DETAIL";
export const REMOVE_SINGLE_ORDER_INFO_DETAIL =
  "REMOVE_SINGLE_ORDER_INFO_DETAIL";
export const ADD_ORDER_ITEMS_INFO_DETAILS = "ADD_ORDER_ITEMS_INFO_DETAILS";
export const REMOVE_ORDER_ITEMS_INFO_DETAILS =
  "REMOVE_ORDER_ITEMS_INFO_DETAILS";
export const REMOVE_ORDER_OVERVIEW_MODAL = "REMOVE_ORDER_OVERVIEW_MODAL";

//multiplierList
export const UPDATE_MULTIPLIER_DATA = "UPDATE_MULTIPLIER_DATA";

//phases
export const GET_UPDATE_PHASES = "GET_UPDATE_PHASES";

//settings
export const GET_UPDATE_SETTINGS_MESSAGE = "GET_UPDATE_SETTINGS_MESSAGE";
export const GET_MESSAGE_HISTORY = "GET_MESSAGE_HISTORY";
export const REMOVE_MESSAGE_HISTORY = "REMOVE_MESSAGE_HISTORY";
export const UPDATE_PRINT_SETTING = "UPDATE_PRINT_SETTING";
export const UPDATE_BOARD_SIZES = "UPDATE_BOARD_SIZES";

//calc-settings

export const GET_UPDATED_CALC_SETTING = "GET_UPDATED_CALC_SETTING";
