import React from "react";
import { ExitIcon } from "./icons";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import { routesConstant } from "../../routes/routeConstant";
import userIcon from "../../assets/icons/user.svg";
import { dateToDDMMYYYY, dateToHHMM } from "../../global/helpers";
import { displayInfoToast } from "../../global/displayToast";
import { useEffect } from "react";
import { useState } from "react";
import { phasesId } from "../../utils/StaticData";

const Footer = (props) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [orderCount, setOrderCount] = useState(0);
  const [completedOrderCount, setCompletedOrderCount] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [completedItemCount, setCompletedItemCount] = useState(0);

  const handleLogout = () => {
    props.logout();
    navigate(routesConstant.LOGIN);
    displayInfoToast("Logout Successful !");
  };

  let fullName = "";
  if (user && user.data) {
    const { first_name, last_name } = user.data;
    fullName = first_name + " " + last_name;
  }

  useEffect(() => {
    let tempOrderCount = 0;
    let tempCompletedOrderCount = 0;
    let tempItemCount = 0;
    let tempCompletedItemCount = 0;

    if (props.ordersInfo) {
      Object.entries(props.ordersInfo).forEach(([date, ordersInfoData]) => {
        Object.entries(ordersInfoData?.orders).forEach(
          ([orderId, orderInfo]) => {
            if (
              orderInfo?.status?.completed?.complete_percentage ===
              phasesId.boxed
            ) {
              tempCompletedOrderCount++;
            }
          }
        );
        tempOrderCount += Object.keys(ordersInfoData?.orders).length;
      });
      setOrderCount(tempOrderCount);
      setCompletedOrderCount(tempCompletedOrderCount);
    }

    if (props.categoryOrders) {
      Object.entries(props.categoryOrders).forEach(
        ([date, categoryOrdersData]) => {
          tempItemCount += categoryOrdersData.itemIds.length;
        }
      );
      setItemCount(tempItemCount);
    }

    if (props.itemInfo) {
      Object.entries(props.itemInfo).forEach(([itemId, itemInfoData]) => {
        if (itemInfoData?.item_phase >= phasesId.completed) {
          tempCompletedItemCount++;
        }
      });
      setCompletedItemCount(tempCompletedItemCount);
    }
  }, [props.ordersInfo, props.categoryOrders, props.itemInfo]);

  return (
    <footer className="px-3 bg-primary d-flex justify-content-between align-items-center footer">
      <div className="d-flex align-items-center">
        <Link
          onClick={handleLogout}
          to={routesConstant.LOGIN}
          className="p-0 nav-link d-flex align-items-center"
        >
          <ExitIcon color="white" width="32" />
        </Link>
        <div className="d-flex align-items-center ms-2">
          <img className="ms-4" src={userIcon} alt="" width={32} />
          <span className="text-white mx-2">{fullName}</span>
          <div
            className="vr text-white mx-3"
            style={{ width: "2px", height: "30px" }}
          ></div>
          {props.categoryOrders && (
            <>
              <div className="text-white">
                Items: {completedItemCount}/{itemCount}{" "}
                {itemCount > 0 && (
                  <span>
                    - {((completedItemCount / itemCount) * 100).toFixed(2)} %
                    Completed
                  </span>
                )}
              </div>
              <div
                className="vr text-white mx-3"
                style={{ width: "2px", height: "30px" }}
              ></div>
              <div className="text-white">
                Orders: {completedOrderCount}/{orderCount}{" "}
                {orderCount > 0 && (
                  <span>
                    - {((completedOrderCount / orderCount) * 100).toFixed(2)}%
                    Completed
                  </span>
                )}
              </div>
              <div
                className="vr text-white mx-3"
                style={{ width: "2px", height: "30px" }}
              ></div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex">
        <div
          className="vr text-white me-2 mt-2"
          style={{ width: "2px", height: "35px" }}
        ></div>
        <div className="d-flex flex-column">
          <span className="text-center text-white">
            LU: {dateToDDMMYYYY(new Date())}
          </span>
          <span className="text-center text-white">
            {dateToHHMM(new Date())}
          </span>
        </div>
      </div>
    </footer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Footer);
