import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../api";
import {
  // deleteCalcSettingsApi,
  editCalcSettingsApi,
  getCalcSettingsApi,
} from "../../api/calcSettings";
import deleteIcon from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/pencil.svg";
import resetIcon from "../../assets/icons/reset.svg";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../global/displayToast";
import { getUpdatedCalcSetting } from "../../redux/actions/calcSettingsActions";
import { orderToken } from "../../utils/StaticData";
import CakeSpinner from "../common/CakeSpinner";
import Layout from "../common/layout";

const FillingsSetting = () => {
  const [settingsLoader, setSettingsLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const { calcSettingsList } = useSelector((state) => state.calcSettings);

  const dispatch = useDispatch();

  const initialState = {
    flavor: "",
    large_cc_weekday_walkin: "",
    large_cc_weekend_walkin: "",
    large_cc_batch: "",
    mini_cc_weekday_walkin: "",
    mini_cc_weekend_walkin: "",
    mini_cc_batch: "",
    recipe_id: "",
    id: "",
  };

  const [state, setState] = useState(initialState);

  const getCalcSettings = async () => {
    setToken(orderToken);
    setSettingsLoader(true);
    const res = await getCalcSettingsApi();
    if (res && res.success === true) {
      // setCalcSettings(res.data);
      dispatch(getUpdatedCalcSetting(res?.data));
    }
    setSettingsLoader(false);
  };

  useEffect(() => {
    if (isEmpty(calcSettingsList)) {
      getCalcSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcSettingsList]);

  const handleSubmit = async () => {
    const {
      flavor,
      large_cc_weekday_walkin,
      large_cc_weekend_walkin,
      large_cc_batch,
      mini_cc_weekday_walkin,
      mini_cc_weekend_walkin,
      mini_cc_batch,
      recipe_id,
    } = state;

    if (
      flavor &&
      large_cc_weekday_walkin &&
      large_cc_weekend_walkin &&
      large_cc_batch &&
      mini_cc_weekday_walkin &&
      mini_cc_weekend_walkin &&
      mini_cc_batch &&
      recipe_id
    ) {
      setUpdateLoader(true);
      const data = {
        flavor: flavor,
        large_cc_weekday_walkin: parseInt(large_cc_weekday_walkin),
        large_cc_weekend_walkin: parseInt(large_cc_weekend_walkin),
        large_cc_batch: parseInt(large_cc_batch),
        mini_cc_weekday_walkin: parseInt(mini_cc_weekday_walkin),
        mini_cc_weekend_walkin: parseInt(mini_cc_weekend_walkin),
        mini_cc_batch: parseInt(mini_cc_batch),
        recipe_id: parseInt(recipe_id),
      };

      const res = await editCalcSettingsApi(data, state?.id);
      if (res && res.success === true) {
        setState(initialState);
        const updatedCalcSetting = calcSettingsList.map((settingData) => {
          if (settingData.id === res.data.id) return res.data;
          return settingData;
        });
        dispatch(getUpdatedCalcSetting(updatedCalcSetting));
        displaySuccessToast(res.message);
      }
      setUpdateLoader(false);
    } else {
      displayErrorToast("Please fill all fields", 3000);
    }
  };

  const handleEdit = (settingData) => {
    setState({
      flavor: settingData.flavor,
      large_cc_weekday_walkin: settingData.large_cc_weekday_walkin.toString(),
      large_cc_weekend_walkin: settingData.large_cc_weekend_walkin.toString(),
      large_cc_batch: settingData.large_cc_batch.toString(),
      mini_cc_weekday_walkin: settingData.mini_cc_weekday_walkin.toString(),
      mini_cc_weekend_walkin: settingData.mini_cc_weekend_walkin.toString(),
      mini_cc_batch: settingData.mini_cc_batch.toString(),
      recipe_id: settingData.recipe_id.toString(),
      id: settingData.id,
    });
  };

  const handleDelete = async (id) => {
    // const res = await deleteCalcSettingsApi(id);
    // if (res && res.success === true) {
    //   displaySuccessToast(res.message);
    // }
  };

  const handleCancel = () => {
    setState(initialState);
  };
  return (
    <>
      <Layout>
        <Container>
          <h6 className="text-primary h3 border-bottom-primary borde-primary font-weight-bold mb-3 pb-2">
            Bakers Calculator: Settings
          </h6>
          {settingsLoader ? (
            <CakeSpinner />
          ) : (
            <>
              <h2 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2 ">
                Daily Bake Numbers
              </h2>
              <div className="d-flex justify-content-center text-center pb-5">
                <section className="overflow-auto">
                  <table className="editable-table">
                    <colgroup>
                      <col width={230} />
                      <col width={100} />
                      <col width={100} />
                      <col width={100} />
                      <col width={100} />
                      <col width={100} />
                      <col width={100} />
                      <col width={230} />
                      <col width={230} />
                    </colgroup>
                    <thead>
                      <tr className="text-center">
                        <th></th>
                        <td colSpan="3">
                          <div className="bottomBorder mx-2 p-1">
                            Large Cupcakes
                          </div>
                        </td>
                        <td colSpan="3">
                          <div className="bottomBorder mx-2 p-1 ">
                            Mini Cupcakes
                          </div>
                        </td>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <td className="text-start bottomBorder">Flavour</td>
                        <td className="bottomBorder">Weekday Walk in</td>
                        <td className="bottomBorder">Weekend Walk in</td>
                        <td className="bottomBorder">Batch</td>
                        <td className="bottomBorder">Weekday Walk in</td>
                        <td className="bottomBorder">Weekend Walk in</td>
                        <td className="bottomBorder">Batch</td>
                        <td className="bottomBorder">Recipe IDs</td>
                        <td className="bottomBorder"></td>
                      </tr>
                    </thead>
                    <tbody className="table-bordered table-light">
                      {calcSettingsList &&
                        calcSettingsList.map((settingData) => (
                          <tr key={settingData?.id}>
                            <td className="text-start cellBorder">
                              {settingData?.flavor}
                            </td>
                            <td>{settingData?.large_cc_weekday_walkin}</td>
                            <td>{settingData?.large_cc_weekend_walkin}</td>
                            <td className="cellBorder">
                              {settingData?.large_cc_batch}
                            </td>
                            <td>{settingData?.mini_cc_weekday_walkin}</td>
                            <td>{settingData?.mini_cc_weekend_walkin}</td>
                            <td className="cellBorder">
                              {settingData?.mini_cc_batch}
                            </td>
                            <td className="cellBorder">
                              {settingData?.recipe_id}
                            </td>
                            <td>
                              <img
                                className="cursorPointer edit-icon me-1"
                                alt="edit"
                                src={editIcon}
                                width={20}
                                onClick={() => handleEdit(settingData)}
                              />
                              <img
                                className="cursorPointer edit-icon"
                                alt="edit"
                                src={deleteIcon}
                                width={20}
                                onClick={() => handleDelete(settingData?.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td className="text-start cellBorder">Chocolate</td>
                        <td>100</td>
                        <td>90</td>
                        <td className="cellBorder">10</td>
                        <td>10</td>
                        <td>100</td>
                        <td className="cellBorder">90</td>
                        <td className="cellBorder">10</td>
                        <td>
                          <img
                            className="cursorPointer edit-icon me-1"
                            alt="edit"
                            src={editIcon}
                            width={20}
                          />
                          <img
                            className="cursorPointer edit-icon"
                            alt="edit"
                            src={deleteIcon}
                            width={20}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start cellBorder">White</td>
                        <td>100</td>
                        <td>90</td>
                        <td className="cellBorder">10</td>
                        <td>10</td>
                        <td>100</td>
                        <td className="cellBorder">90</td>
                        <td className="cellBorder">10</td>
                        <td>
                          <img
                            className="cursorPointer edit-icon me-1"
                            alt="edit"
                            src={editIcon}
                            width={20}
                          />
                          <img
                            className="cursorPointer edit-icon"
                            alt="edit"
                            src={deleteIcon}
                            width={20}
                          />
                        </td>
                      </tr>

                      <tr>
                        <td className="text-start cellBorder">Red </td>
                        <td>100</td>
                        <td>90</td>
                        <td className="cellBorder">10</td>
                        <td>10</td>
                        <td>100</td>
                        <td className="cellBorder">90</td>
                        <td className="cellBorder">10</td>
                        <td>
                          <img
                            className="cursorPointer edit-icon me-1"
                            alt="edit"
                            src={editIcon}
                            width={20}
                          />
                          <img
                            className="cursorPointer edit-icon"
                            alt="edit"
                            src={deleteIcon}
                            width={20}
                          />
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td className="text-start cellBorder bottomBorder p-1">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-75 cakeFillingInput text-center"
                            value={state.flavor}
                            onChange={(e) => {
                              setState({
                                ...state,
                                flavor: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.large_cc_weekday_walkin}
                            onChange={(e) => {
                              setState({
                                ...state,
                                large_cc_weekday_walkin: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.large_cc_weekend_walkin}
                            onChange={(e) => {
                              setState({
                                ...state,
                                large_cc_weekend_walkin: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="cellBorder bottomBorder ">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.large_cc_batch}
                            onChange={(e) => {
                              setState({
                                ...state,
                                large_cc_batch: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.mini_cc_weekday_walkin}
                            onChange={(e) => {
                              setState({
                                ...state,
                                mini_cc_weekday_walkin: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.mini_cc_weekend_walkin}
                            onChange={(e) => {
                              setState({
                                ...state,
                                mini_cc_weekend_walkin: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="cellBorder bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-50 mx-auto cakeFillingInput text-center"
                            value={state.mini_cc_batch}
                            onChange={(e) => {
                              setState({
                                ...state,
                                mini_cc_batch: e.target.value.replace(
                                  /\D/g,
                                  ""
                                ),
                              });
                            }}
                          />
                        </td>
                        <td className="cellBorder bottomBorder">
                          <Form.Control
                            disabled={!state?.id}
                            type="text"
                            className="custom-input w-25 mx-auto cakeFillingInput text-center"
                            value={state.recipe_id}
                            onChange={(e) => {
                              setState({
                                ...state,
                                recipe_id: e.target.value.replace(/\D/g, ""),
                              });
                            }}
                          />
                        </td>
                        <td className="bottomBorder ">
                          <div className="d-flex justify-content-center">
                            <div
                              className={`me-2 cursorPointer  ${
                                !state?.id && "pe-none"
                              } `}
                            >
                              <Button
                                disabled={!state?.id}
                                onClick={() => handleSubmit()}
                                className="p-2 m-1 "
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  <span>Update</span>
                                  {updateLoader && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                              </Button>
                            </div>
                            {state.id && (
                              <span
                                className="cursorPointer "
                                onClick={() => handleCancel()}
                              >
                                <img src={resetIcon} width={30} alt="reset" />
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>

              <h2 className="text-secondary h4 border-bottom-secondary borde-primary font-weight-bold mb-3 pb-2 ">
                Category IDs
              </h2>
              <div className="editable-table">
                <div className="d-flex">
                  <div className="pe-5">Large cupcake</div>
                  <div>
                    <Form.Control
                      type="text"
                      placeholder="19,22,192,69"
                      className="custom-input "
                    />
                  </div>
                  <div>
                    <img
                      className="cursorPointer edit-icon ms-3"
                      alt="edit"
                      src={editIcon}
                      width={20}
                    />
                  </div>
                </div>
                <div className="d-flex py-3 ">
                  <div className="pe-5 me-2">Mini cupcake </div>
                  <div>
                    <Form.Control
                      type="text"
                      placeholder="19,22,192,69"
                      className="custom-input "
                    />
                  </div>
                  <div>
                    <img
                      className="cursorPointer edit-icon ms-3"
                      alt="edit"
                      src={editIcon}
                      width={20}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default FillingsSetting;
