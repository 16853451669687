import client from './index';
import { apiEndPoint } from "./apiEndPoint";
import { handleError } from './index';

export const signInAPI = async (postData) => {
  try {
    const res = await client.post(apiEndPoint.LOGIN_ENDPOINT, postData);
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};
