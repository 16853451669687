import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import {
  getConfigLevelsApi,
  editConfigLevelApi,
  addConfigLevelApi,
} from "../../api/configureLevel";
import { getAllPagesApi } from "../../api/pages";
import { useSelector } from "react-redux";
import { getLevelsApi } from "../../api/level";
import Layout from "../../components/common/layout";
import ConfigureLevelTable from "../../components/ConfigureLevels/ConfigureLevelTable";
import { displaySuccessToast } from "../../global/displayToast";
import { setToken } from "../../api";

const ConfigureLevels = (props) => {
  const { token } = useSelector((state) => state.user);

  const [levelName, setLevelName] = useState("");
  const [pagesList, setPagesList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [configLevel, setConfigLevels] = useState([]);
  const [onSaveLoader, setOnSaveLoader] = useState(false);
  const [mainConfigLevel, setMainConfigLevels] = useState([]);

  const location = useLocation();
  const initialLevelName = location.state?.leveName.trim();

  const levelId = levelList.find(
    (level) => level.name.trim() === levelName.trim()
  )?.id;

  const getLevelsData = async () => {
    setToken(token);
    setLoader(true);
    const res = await getLevelsApi();

    if (res && res.success === true) {
      setLevelList(res.data);
      if (initialLevelName) {
        setLevelName(initialLevelName);
      } else {
        setLevelName(res.data[0].name);
      }
    }
    setLoader(false);
  };

  const getConfigLevels = async () => {
    setToken(token);
    setLoader(true);
    const res = await getConfigLevelsApi();

    if (res && res.success === true) {
      setConfigLevels(res.data);
      setMainConfigLevels(res.data);
    }
    setLoader(false);
  };

  const getAllPages = async () => {
    setToken(token);
    setLoader(true);
    const res = await getAllPagesApi();

    if (res && res.success === true) {
      setPagesList(res.data);
    }
    setLoader(false);
  };

  useEffect(() => {
    getLevelsData();
    getConfigLevels();
    getAllPages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleSave = async () => {
    const payload = configLevel.find((level) => level.id === levelId);
    const isLevelIdAvailable = mainConfigLevel.some(
      (level) => level.id === levelId
    );

    setOnSaveLoader(true);
    if (isLevelIdAvailable) {
      const res = await editConfigLevelApi(
        { access: { ...payload.access } },
        levelId
      );
      if (res && res.success === true) {
        setConfigLevels((prev) =>
          prev.map((level) => {
            if (level.id === res.data.id) return res.data;
            return level;
          })
        );
        displaySuccessToast(res.message);
      }
    } else {
      const res = await addConfigLevelApi(payload);
      if (res && res.success === true) {
        setConfigLevels((prev) => [...prev, res.data]);
        displaySuccessToast(res.message);
      }
    }
    setOnSaveLoader(false);
  };

  return (
    <Layout loader={loader}>
      <Container>
        <div className="d-flex justify-content-between align-items-center mb-3 border-bottom-primary borde-primary font-weight-bold mb-3 pb-2">
          <h6 className="text-primary h3 ">Configure Levels</h6>
          <Button
            size="sm"
            type="button"
            className="m-2"
            onClick={onHandleSave}
          >
            <span className={onSaveLoader ? "me-2" : ""}>Save</span>
            <span>
              {onSaveLoader && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </span>
          </Button>
        </div>
        <Row>
          <Col sm={12}>
            <div className="d-flex align-items-center mb-2">
              <span className="fw-bold me-2"> Configure level For :</span>
              <Form.Select
                className="w-auto"
                size="sm"
                value={levelName}
                onChange={(e) => {
                  setLevelName(e.target.value);
                }}
              >
                {levelList.map((level) => {
                  return <option key={level?.id}>{level?.name}</option>;
                })}
              </Form.Select>
            </div>
            <ConfigureLevelTable
              {...{
                configLevel,
                pagesList,
                levelId,
                setConfigLevels,
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default ConfigureLevels;
