import { isArray, isEmpty } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
//apis
import { editItemInfoApi, editMulItemInfoApi } from "../../api/iteminfo";
import { getAppSettingByIDApi } from "../../api/settings";
//helpers and static datas
import editIcon from "../../assets/icons/white-pencil.svg";
import { CheckedIcon, UserIcon } from "../../components/common/icons";
import {
  getCheckboxFilteredOrder,
  hexToRgbA,
  removeDuplicate,
} from "../../global/helpers";
import { updateBoardSizes } from "../../redux/actions/settingsAction";
import {
  modifierCatData,
  phasesId,
  tiersData,
  setting,
  modifierIdData,
} from "../../utils/StaticData";
//components
import NoData from "../common/NoData";
import SortButton from "../common/SortButton";

const sortButtonsData = [
  { name: "SID", label: "S#" },
  { name: "order_time", label: "Time" },
  { name: "local_id", label: "Order #" },
];

const displayHeaderDateFormat = "dddd, MMMM D";

const modifiersArr = [
  { flavor: "/" },
  { filling: "///" },
  { iced_color: "//" },
  { iced: "//" },
  { borders: "" },
];

const getShape = (shape) => {
  switch (shape) {
    case "rnd":
      return "round";
    case "sht":
      return "sheet";
    case "sqr":
      return "square";
    default:
      return "";
  }
};

const Production = (props) => {
  const {
    ordersByDate,
    itemInfo,
    setItemInfo,
    productionFilters,
    setFilterDropdown,
    currentdropDownValue,
  } = props;

  const [displayOrdersByDate, setDisplayOrdersByDate] = useState([]);
  const [edit, setEdit] = useState(null);
  const [currentNote, setCurrentNote] = useState({
    note_check: false,
    note: "",
  });
  const [activeSortingField, setActiveSortingField] = useState("order_time");
  const [sortOrder, setSortOrder] = useState(false);
  const [isToDisplayAllOrders, setIsToDisplayAllOrders] = useState(false);
  const [selectBoardSizes, setSelectBoardSizes] = useState([]);

  const { boardSizes } = useSelector((state) => state.settings);
  const { data: userData } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  // <------------------------------------------------------Helper Functions ------------------------------------------------------>

  const getInitialPhaseRequirements = (row) => {
    const fixedModifiers = {
      [modifierCatData.filling]: false,
      [modifierCatData.iced_color]: false,
      [modifierCatData.iced]: false,
      [modifierCatData.borders]: false,
      [modifierCatData.board]: false,
    };
    if (row === 1) {
      return {
        [modifierCatData.flavor[0]]: false, // modiCat = 8
        ...fixedModifiers,
      };
    } else {
      return {
        [modifierCatData.flavor[1]]: false, // modiCat = 9
        ...fixedModifiers,
      };
    }
  };

  const getPhaseRequirements = (item, row, currModifiers, modifierInfo) => {
    const { modifierName, modifierCheck } = modifierInfo;
    let modifierId = modifierCatData[modifierName];
    if (modifierName === "flavor") {
      if (row === 1) {
        modifierId = modifierCatData[modifierName][0]; // modiCat = 8
      } else {
        modifierId = modifierCatData[modifierName][1]; // modiCat = 9
      }
    }
    return {
      ...(item ? item.phase_requirements : {}),
      [row]: {
        [phasesId.cakeAssembled]: {
          ...currModifiers,
          [modifierId]: modifierCheck,
        },
      },
    };
  };

  const checkPhase = (item, row, modiField) => {
    let check = false;
    if (
      item?.phase_requirements &&
      item?.phase_requirements.hasOwnProperty(row) &&
      item?.phase_requirements[row].hasOwnProperty(phasesId.cakeAssembled)
    ) {
      const cakeAssembledPhaseId =
        item?.phase_requirements[row][phasesId.cakeAssembled];

      if (cakeAssembledPhaseId && !isEmpty(cakeAssembledPhaseId)) {
        if (modiField === "flavor") {
          if (row === 1) {
            check = cakeAssembledPhaseId[modifierCatData.flavor[0]]; // modiCat = 8
          } else {
            check = cakeAssembledPhaseId[modifierCatData.flavor[1]]; // modiCat = 9
          }
        } else {
          check = cakeAssembledPhaseId[modifierCatData[modiField]];
        }
      }
    }
    return check;
  };

  const getItemColor = (requiredItem) => {
    let itemColor = "#ffffff";
    if (requiredItem && requiredItem?.user && requiredItem?.user?.color) {
      itemColor = requiredItem?.user?.color;
    }
    return itemColor;
  };

  const updateItemInfo = (itemId, updateKey, updateData, itemIdsArray) => {
    let updatedItemInfo = { ...itemInfo };
    if (itemIdsArray && !itemId) {
      itemIdsArray.forEach((itemId) => {
        updatedItemInfo = {
          ...updatedItemInfo,
          [itemId]: {
            ...itemInfo[itemId],
            [updateKey]: updateData,
          },
        };
      });
    } else {
      updatedItemInfo = {
        ...updatedItemInfo,
        [itemId]: {
          ...itemInfo[itemId],
          [updateKey]: updateData,
        },
      };
    }

    setItemInfo(updatedItemInfo);
  };

  const handleEditItemInfo = async (payload, itemId) => {
    const res = await editItemInfoApi(payload, itemId);
    const updatedItemInfo = {
      ...itemInfo,
      [itemId]: res.data,
    };

    setItemInfo(updatedItemInfo);
  };

  const handleEditMulItemInfo = async (payload, itemIdsArray) => {
    const mulItemPayload = {
      ids: itemIdsArray,
      ...payload,
    };
    const res = await editMulItemInfoApi(mulItemPayload);
    const updatedItemInfo = {
      ...itemInfo,
      ...res.data,
    };
    setItemInfo(updatedItemInfo);
  };

  const getItemId = (item) => {
    let itemId = item?.id;
    if (item?.cake_items) {
      itemId = item?.cake_items[0].id;
    }
    return itemId;
  };

  const checkAllItemsBaked = (order) => {
    const check = order?.items.every((item) => {
      if (item?.cake_items) {
        return item?.cake_items.every((cakeItem) => {
          if (itemInfo[cakeItem.id]) {
            return itemInfo[cakeItem.id]?.item_phase >= phasesId?.baked;
          }
          return false;
        });
      } else {
        if (itemInfo[item?.id]) {
          return itemInfo[item?.id]?.item_phase >= phasesId?.baked;
        }
        return false;
      }
    });
    return check;
  };

  // <----------------------------------------------------Board Sizes Functions ------------------------------------------------------>

  const getAppSettingByID = async () => {
    const res = await getAppSettingByIDApi(setting.board_sizes_ID);
    dispatch(updateBoardSizes({ ...res?.data?.settings }));
  };

  useEffect(() => {
    if (isEmpty(boardSizes)) {
      getAppSettingByID();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardSizes]);

  const getBoardSize = (item, cakeItem) => {
    const appInfo = item?.product?.app_info;
    let boardSizeValue = "";
    if (appInfo?.ck_tiers === 1 || !appInfo.ck_tiers) {
      boardSizeValue = appInfo?.ck_boardsize?.sizes
        ? Object.values(appInfo?.ck_boardsize?.sizes)[0]
        : appInfo?.ck_boardsize;
    } else {
      const requiredModifierItem = item?.modifieritems.find(
        (modiItem) => modiItem?.modifier?.modifierCat === modifierCatData.build
      );
      if (requiredModifierItem) {
        const cakeItemIndex = [...item?.cake_items]
          .sort((a, b) => {
            const a_cakeSize = a.product.app_info.ck_size;
            const b_cakeSize = b.product.app_info.ck_size;
            return +a_cakeSize > +b_cakeSize;
          })
          .findIndex((item) => item?.id === cakeItem?.id);

        const modifierId = requiredModifierItem?.modifier?.id;
        let requiredKey;
        requiredKey = Object.keys(appInfo).find(
          (key) => key === modifierId.toString()
        );
        if (!requiredKey) {
          requiredKey = Object.keys(appInfo).find((key) => {
            const keyStringArr = key.split("_");
            if (
              keyStringArr[keyStringArr.length - 1] === modifierId.toString()
            ) {
              return key;
            }
            return "";
          });
        }
        const requiredAppInfo = appInfo[requiredKey];
        if (isArray(requiredAppInfo)) {
          boardSizeValue = requiredAppInfo[cakeItemIndex];
        } else {
          const cakeSizes = Object.values(requiredAppInfo.sizes);
          boardSizeValue = cakeSizes[cakeItemIndex];
        }
      }
    }
    if (boardSizeValue) {
      const boardSizeId = Object.entries(boardSizes).find(
        ([id, value]) => value === boardSizeValue
      )[0];
      return {
        boardSizeValue,
        boardSizeId,
      };
    }
    return "";
  };

  const getBoardSizeValue = (id) => {
    const itemInfoObj = itemInfo[id];

    let boardId = false;
    if (itemInfoObj) {
      boardId = checkPhase(itemInfoObj, tiersData.tier_one, "board");
    }
    if (boardId) {
      return boardSizes[boardId];
    } else {
      const requiredLayoutObj = selectBoardSizes.find(
        (ele) => ele.item_id === id
      );
      if (!isEmpty(requiredLayoutObj)) {
        return requiredLayoutObj.board_size?.boardSizeValue;
      } else {
        return "";
      }
    }
  };

  const handleOnChangeBoardSize = (selectValue, item) => {
    const itemId = item.id;
    const itemInfoObj = itemInfo[itemId];
    const boardSizeId = Object.entries(boardSizes).find(
      ([id, value]) => value === selectValue
    )[0];
    let boardId = false;
    if (itemInfoObj) {
      boardId = checkPhase(itemInfoObj, tiersData.tier_one, "board");
    }
    if (boardId) {
      const modifierInfo = {
        modifierName: "board",
        modifierCheck: true,
      };
      handleOnBoardCheck(item, modifierInfo, boardSizeId);
    } else {
      if (selectValue) {
        const boardSize = {
          boardSizeValue: selectValue,
          boardSizeId,
        };

        setSelectBoardSizes((prev) =>
          prev.map((ele) => {
            if (ele.item_id === itemId) {
              return {
                ...ele,
                board_size: boardSize,
              };
            }
            return ele;
          })
        );
      }
    }
  };

  const handleOnBoardCheck = async (item, modifierInfo, boardSizeId) => {
    const itemId = item.id;
    const itemInfoObj = itemInfo[itemId];
    const updatedModifierInfo = {
      modifierName: modifierInfo.modifierName,
      modifierCheck: modifierInfo.modifierCheck
        ? boardSizeId ||
          selectBoardSizes.find((ele) => ele.item_id === itemId)?.board_size
            ?.boardSizeId
        : false,
    };
    const appInfo = item?.product?.app_info;
    let rows = [1];
    if (appInfo?.ck_piece_cnt === 2 || appInfo?.ck_build === "dl") {
      rows.push(2);
    }
    let tempItemInfoObj = { ...itemInfoObj };

    rows.forEach((row) => {
      if (
        tempItemInfoObj?.phase_requirements &&
        !isEmpty(
          tempItemInfoObj?.phase_requirements &&
            tempItemInfoObj?.phase_requirements[row]
        )
      ) {
        const cakeAssembledPhaseId =
          tempItemInfoObj?.phase_requirements[row][phasesId.cakeAssembled];
        let tempPhaseRequirement = getPhaseRequirements(
          tempItemInfoObj,
          row,
          cakeAssembledPhaseId,
          updatedModifierInfo
        );
        const updatedPhaseRequirement = {
          ...tempItemInfoObj.phase_requirements,
          ...tempPhaseRequirement,
        };
        tempItemInfoObj = {
          ...tempItemInfoObj,
          phase_requirements: updatedPhaseRequirement,
        };
      } else {
        let tempPhaseRequirement = getPhaseRequirements(
          tempItemInfoObj,
          row,
          getInitialPhaseRequirements(row),
          updatedModifierInfo
        );
        const updatedPhaseRequirement = {
          ...tempItemInfoObj.phase_requirements,
          ...tempPhaseRequirement,
        };
        tempItemInfoObj = {
          ...tempItemInfoObj,
          phase_requirements: updatedPhaseRequirement,
        };
      }
    });

    updateItemInfo(
      itemId,
      "phase_requirements",
      tempItemInfoObj.phase_requirements
    );

    const payload = {
      phase_requirements: tempItemInfoObj.phase_requirements,
      user_id: null,
    };

    handleEditItemInfo(payload, itemId);
  };

  const addFillingAndIcedValues = (
    modifiers,
    tempFillingValues,
    tempIcedValues
  ) => {
    if (modifiers?.modifierCat === modifierCatData?.filling) {
      tempFillingValues.push({
        name: modifiers?.kitchen_print_name,
        value: modifiers?.id,
      });
    } else if (modifiers?.modifierCat === modifierCatData?.iced) {
      tempIcedValues.push({
        name: modifiers?.kitchen_print_name,
        value: modifiers?.id,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(ordersByDate) && !isEmpty(boardSizes)) {
      let tempPhaseRequirement = [];
      let tempFillingValues = [];
      let tempIcedValues = [];

      const updateCategoryOrder = Object.entries(ordersByDate).map(
        ([orderDate, orderData]) => {
          let updatedOrders = [];
          if (orderData?.orders) {
            updatedOrders = Object.entries(orderData?.orders).map(
              ([orderId, orderDetail]) => {
                orderDetail?.items.forEach((item) => {
                  if (item?.cake_items) {
                    item?.cake_items.forEach((cakeItem) => {
                      tempPhaseRequirement.push({
                        item_id: cakeItem.id,
                        board_size: getBoardSize(item, cakeItem),
                      });

                      cakeItem?.modifieritems.forEach((modiItem) => {
                        const modifiers = modiItem?.modifier;
                        addFillingAndIcedValues(
                          modifiers,
                          tempFillingValues,
                          tempIcedValues
                        );
                      });
                    });
                  } else {
                    tempPhaseRequirement.push({
                      item_id: item.id,
                      board_size: getBoardSize(item),
                    });
                    item?.modifieritems.forEach((modiItem) => {
                      const modifiers = modiItem?.modifier;
                      addFillingAndIcedValues(
                        modifiers,
                        tempFillingValues,
                        tempIcedValues
                      );
                    });
                    addFillingAndIcedValues();
                  }
                });
                return [orderId, orderDetail];
              }
            );
          }
          return [
            orderDate,
            { ...orderData, orders: Object.fromEntries(updatedOrders) },
          ];
        }
      );
      const removedEmptyOrders = updateCategoryOrder.filter(
        ([orderDate, orderData]) => !isEmpty(orderData)
      );
      setDisplayOrdersByDate(Object.fromEntries(removedEmptyOrders));
      setSelectBoardSizes(tempPhaseRequirement);

      setFilterDropdown([
        {
          filter: "fillings",
          filterValue: removeDuplicate(tempFillingValues, "value"),
        },
        {
          filter: "iced",
          filterValue: removeDuplicate(tempIcedValues, "value"),
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersByDate, boardSizes]);

  // <----------------------------------------------------Modifiers Functions -------------------------------------------------------->

  const handleModifierDisabled = (itemInfoData) => {
    if (itemInfoData && itemInfoData?.item_phase >= phasesId.baked) {
      return false;
    }
    return true;
  };

  const handleModifiersOnChange = async (
    itemInfo,
    itemId,
    row,
    modifierInfo
  ) => {
    let phaseRequirements = {};
    if (
      itemInfo[itemId] &&
      itemInfo[itemId]?.phase_requirements &&
      !isEmpty(
        itemInfo[itemId]?.phase_requirements &&
          itemInfo[itemId]?.phase_requirements[row]
      )
    ) {
      const cakeAssembledPhaseId =
        itemInfo[itemId]?.phase_requirements[row][phasesId.cakeAssembled];
      phaseRequirements = getPhaseRequirements(
        itemInfo[itemId],
        row,
        cakeAssembledPhaseId,
        modifierInfo
      );
    } else {
      phaseRequirements = getPhaseRequirements(
        itemInfo[itemId],
        row,
        getInitialPhaseRequirements(row, itemId),
        modifierInfo
      );
    }

    updateItemInfo(itemId, "phase_requirements", phaseRequirements);

    const payload = {
      phase_requirements: phaseRequirements,
      user_id: null,
    };

    handleEditItemInfo(payload, itemId);
  };

  const getModifiersValue = (modifiersItems, modifierValue) => {
    const modifierObj = modifiersItems.find(
      (modiItem) => modiItem?.modifier?.modifierCat === modifierValue
    );
    if (!isEmpty(modifierObj)) {
      return modifierObj?.modifier?.kitchen_print_name;
    }
    return "No modifier";
  };

  const renderLayout = (item) => {
    const appInfo = item?.product?.app_info;

    let rows = [1];
    if (appInfo?.ck_piece_cnt === 2 || appInfo?.ck_build === "dl") {
      rows.push(2);
    }
    return rows.map((row) => (
      <div className="d-flex gap-1 mb-3" key={row}>
        <div className={`cake-shape ${getShape(appInfo?.ck_shape)}`}>
          {appInfo?.ck_size}
        </div>
        <div>
          {modifiersArr.map((modifier) => {
            const modifierName = Object.keys(modifier)[0];
            return (
              <React.Fragment key={`${row}-${item.id}-${modifierName}`}>
                <span className="modifiers">
                  <input
                    disabled={handleModifierDisabled(itemInfo[item?.id])}
                    type="checkbox"
                    id={`${row}-${item.id}-${modifierName}`}
                    className="visually-hidden modiInput"
                    checked={checkPhase(itemInfo[item.id], row, modifierName)}
                    onChange={(e) =>
                      handleModifiersOnChange(itemInfo, item.id, row, {
                        modifierName,
                        modifierCheck: e.target.checked,
                      })
                    }
                  />
                  <label
                    className="mx-1"
                    htmlFor={`${row}-${item.id}-${modifierName}`}
                  >
                    {modifierName === "flavor"
                      ? row === 2
                        ? getModifiersValue(
                            item?.modifieritems,
                            modifierCatData?.flavor[1]
                          )
                        : getModifiersValue(
                            item?.modifieritems,
                            modifierCatData?.flavor[0]
                          )
                      : getModifiersValue(
                          item?.modifieritems,
                          modifierCatData[modifierName]
                        )}
                  </label>
                </span>
                <span>{modifier[modifierName]}</span>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    ));
  };

  // <------------------------------------------------------Notes Functions ---------------------------------------------------------->

  const handleNoteAdd = (itemId) => {
    setEdit(itemId);
    setCurrentNote({ note_check: false, note: "" });
    updateItemInfo(itemId, "notes", " ");
  };

  const handleNoteSave = (item) => {
    setEdit(null);
    const payload = {
      notes: currentNote.note.trim(),
      note_check: currentNote.note_check ? 1 : 0,
    };
    if (item?.cake_items) {
      const itemIdsArray = item.cake_items.map((cakeItem) => cakeItem?.id);

      updateItemInfo(null, "notes", currentNote.note.trim(), itemIdsArray);
      updateItemInfo(null, "note_check", currentNote.note_check, itemIdsArray);

      handleEditMulItemInfo(payload, itemIdsArray);
    } else {
      updateItemInfo(item.id, "notes", currentNote.note.trim());
      updateItemInfo(item.id, "note_check", currentNote.note_check);

      handleEditItemInfo(payload, item.id);
    }
  };

  const handleNoteDelete = () => {
    setEdit(null);
    setCurrentNote({ note_check: false, note: "" });
  };

  const handleNoteEdit = (itemId) => {
    setTimeout(function () {
      const input = document.getElementById(`notes-${itemId}`);
      const end = input.value.length;

      input.setSelectionRange(end, end);
      input.focus();
    }, 0);

    setCurrentNote({
      note: itemInfo[itemId]?.notes,
      note_check: itemInfo[itemId].note_check,
    });
    setEdit(itemId);
  };

  const renderNotes = (item) => (
    <>
      {itemInfo[getItemId(item)] &&
      !isEmpty(itemInfo[getItemId(item)]?.notes) ? (
        <>
          <td></td>
          <td>
            <div className="add-note-box">
              <Form.Group className="me-2 mt-2">
                <Form.Check
                  label=""
                  onChange={(e) =>
                    setCurrentNote((prev) => {
                      return {
                        ...prev,
                        note_check: e.target.checked,
                      };
                    })
                  }
                  checked={
                    edit && edit === getItemId(item)
                      ? currentNote?.note_check
                      : itemInfo[getItemId(item)]?.note_check
                  }
                  type="checkbox"
                  id={`notes-check-${getItemId(item)}`}
                  className="custom-input-box checkbox-24 note"
                  name={"notes"}
                />
              </Form.Group>
              <span className="text-white">Note //&nbsp;</span>
              <textarea
                autoFocus
                id={`notes-${getItemId(item)}`}
                disabled={edit && edit === getItemId(item) ? false : true}
                value={
                  edit && edit === getItemId(item)
                    ? currentNote?.note
                    : itemInfo[getItemId(item)]?.notes
                }
                onChange={(e) =>
                  setCurrentNote((prev) => {
                    return {
                      ...prev,
                      note: e.target.value,
                    };
                  })
                }
              />
              {edit && edit === getItemId(item) ? (
                <div className="d-flex flex-column gap-3 py-2">
                  <Button className="save" onClick={() => handleNoteSave(item)}>
                    Save
                  </Button>
                  <Button className="delete" onClick={handleNoteDelete}>
                    Delete
                  </Button>
                </div>
              ) : (
                <Button onClick={() => handleNoteEdit(getItemId(item))}>
                  <img
                    className="cursorPointer edit-icon"
                    alt="edit"
                    src={editIcon}
                    width={30}
                  />
                </Button>
              )}
            </div>
          </td>
        </>
      ) : (
        <td className="px-5 py-2">
          <Button
            className="add-note"
            onClick={() => handleNoteAdd(getItemId(item))}
          >
            Add Note
          </Button>
        </td>
      )}
    </>
  );

  // <---------------------------------------------------User status Functions ------------------------------------------------------->

  const handleItemActive = async (item) => {
    let requiredItemInfo = itemInfo[item?.id];
    if (item?.cake_items) {
      requiredItemInfo = itemInfo[item.cake_items[0]?.id];
    }
    let userId = userData?.id;
    let updatedUser = {
      color: userData?.color,
      id: userId,
      username: userData?.username,
    };
    if (requiredItemInfo?.user?.id === userId) {
      const user = requiredItemInfo?.user;
      if (user?.color && user.id && user.username) {
        userId = null;
        updatedUser = {
          color: null,
          id: userId,
          username: null,
        };
      }
    }

    const payload = {
      user_id: userId,
    };

    if (item?.cake_items) {
      const itemIdsArray = item.cake_items.map((cakeItem) => cakeItem?.id);

      updateItemInfo(null, "user", updatedUser, itemIdsArray);

      handleEditMulItemInfo(payload, itemIdsArray);
    } else {
      updateItemInfo(item?.id, "user", updatedUser);

      handleEditItemInfo(payload, item.id);
    }
  };

  const handleCurrentUserStatus = (item, itemInfo, currentUserStatus) => {
    const phaseRequirements = itemInfo?.phase_requirements;
    const appInfo = item?.product?.app_info;
    if (phaseRequirements) {
      let rows = [1];
      if (appInfo?.ck_piece_cnt === 2 || appInfo?.ck_build === "dl") {
        rows.push(2);
      }
      const isAnyModifierCheck = rows.some((row) =>
        Object.entries(phaseRequirements).some(([tier, tierData]) =>
          Object.entries(tierData).some(([phaseId, modifierData]) =>
            Object.entries(modifierData).some(
              ([modifierId, modifierCheck]) => modifierCheck === true
            )
          )
        )
      );
      if (isAnyModifierCheck) {
        currentUserStatus = "in-progress";
      }

      if (
        itemInfo?.item_phase &&
        Object.keys(itemInfo?.current_phase).length === rows.length
      ) {
        return [
          currentUserStatus,
          itemInfo?.item_phase === phasesId.cakeAssembled,
        ];
      } else return [currentUserStatus, false];
    } else return [currentUserStatus, false];
  };

  const getcurrentUserStatus = (item) => {
    let currentUserStatus = "default";
    let cakeItemModifiersChecks = [];
    if (item?.cake_items) {
      item?.cake_items.forEach((cakeItem) => {
        const [currentStatus, isCompleted] = handleCurrentUserStatus(
          cakeItem,
          itemInfo[cakeItem.id],
          currentUserStatus
        );
        currentUserStatus = currentStatus;
        cakeItemModifiersChecks.push(isCompleted);
      });
    } else {
      const [currentStatus, isCompleted] = handleCurrentUserStatus(
        item,
        itemInfo[item.id],
        currentUserStatus
      );
      cakeItemModifiersChecks.push(isCompleted);
      currentUserStatus = currentStatus;
    }

    if (
      !isEmpty(cakeItemModifiersChecks) &&
      cakeItemModifiersChecks.every((check) => check)
    ) {
      currentUserStatus = "completed";
    }
    return currentUserStatus;
  };

  const renderIcon = (item) => {
    const currentUserStatus = getcurrentUserStatus(item);
    let requiredItem = itemInfo[item.id];
    if (item?.cake_items) {
      requiredItem = itemInfo[item.cake_items[0]?.id];
    }

    return (
      <Button
        className={`status-btn status-${
          requiredItem && requiredItem?.user && requiredItem?.user?.id
            ? "pink"
            : currentUserStatus
        }`}
        onClick={() => handleItemActive(item)}
      >
        {requiredItem && requiredItem?.user && requiredItem?.user?.id ? (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ minWidth: "185px" }}
          >
            <div className="text-white">{requiredItem?.user?.username}</div>
            <UserIcon color="white" />
          </div>
        ) : currentUserStatus === "completed" ? (
          <CheckedIcon color="white" />
        ) : (
          <UserIcon color="white" />
        )}
      </Button>
    );
  };

  const renderTitleAndIcon = (appInfo, item) => (
    <>
      <td>{}</td>
      <td>
        <div className="item-title">
          {!item?.cake_items && appInfo?.ck_size}{" "}
          {getShape(appInfo?.ck_shape).toUpperCase()} |{" "}
          {!item?.cake_items
            ? appInfo?.ck_build === "sl"
              ? "SINGLE LAYER"
              : "DOUBLE LAYER"
            : "STACKED"}
        </div>
        <hr className="divider" />
      </td>
      <td className="d-flex justify-content-end">{renderIcon(item)}</td>
    </>
  );

  const parentLayout = (item, cakeItem) => {
    return (
      <tr key={cakeItem?.id || item?.id}>
        <td className="d-flex gap-2 align-items-center">
          <Form.Select
            value={getBoardSizeValue(cakeItem?.id || item?.id)}
            className="custom-input board-select"
            onChange={(e) =>
              handleOnChangeBoardSize(e.target.value, cakeItem || item)
            }
          >
            {Object.entries(boardSizes).map(([boardSizeId, boardSizeName]) => (
              <option value={boardSizeName} key={boardSizeId}>
                {boardSizeName}
              </option>
            ))}
          </Form.Select>
          <Form.Group>
            <Form.Check
              onChange={(e) =>
                handleOnBoardCheck(cakeItem || item, {
                  modifierName: "board",
                  modifierCheck: e.target.checked,
                })
              }
              label=""
              id={`board-${cakeItem?.id || item?.id}`}
              checked={checkPhase(
                itemInfo[cakeItem?.id || item?.id],
                tiersData.tier_one,
                "board"
              )}
              type="checkbox"
              className="custom-input-box checkbox-24 "
              name={"board-checkBox"}
            />
          </Form.Group>
        </td>
        <td>{renderLayout(cakeItem || item)}</td>
      </tr>
    );
  };

  // <-------------------------------------------------Render order and item Functions ----------------------------------------------->

  const renderItem = (item) => {
    const appInfo = item?.product?.app_info;
    let requiredItem = itemInfo[item.id];
    if (item?.cake_items) {
      requiredItem = itemInfo[item.cake_items[0]?.id];
    }

    return (
      <table
        className="w-100 table-fixed item-table"
        key={item.id}
        style={{
          backgroundColor: `${hexToRgbA(getItemColor(requiredItem))}`,
        }}
        cellPadding="10"
        cellSpacing="10"
      >
        <colgroup>
          <col width={180} />
          <col style={{ minWidth: "650px" }} />
          <col width={200} />
        </colgroup>
        <tbody className="border-0">
          <tr>{renderTitleAndIcon(appInfo, item)}</tr>
          {item?.cake_items
            ? item?.cake_items.map((cakeItem) => parentLayout(item, cakeItem))
            : parentLayout(item)}

          <tr>{renderNotes(item)}</tr>
        </tbody>
      </table>
    );
  };

  const renderOrders = (ordersArray) => {
    return ordersArray.map((order) => (
      <tr key={order?.id}>
        <td>
          <div className="mt-3 d-flex flex-column align-items-center">
            <div className="section">S1</div>
            <div>{moment(order?.pickup_time).format("hh:mm a")}</div>
            <div className="customer-name">
              {order?.customer?.first_name + " " + order?.customer?.last_name}
            </div>
            <div>{order?.local_id}</div>
            <div
              className={`order-status ${
                checkAllItemsBaked(order) ? "baked" : "pending"
              }`}
            >
              {checkAllItemsBaked(order) ? "Baked" : "Pending"}
            </div>
          </div>
        </td>
        <td className="px-0">
          <table className="w-100 table-fixed">
            <colgroup>
              <col style={{ minWidth: "1050px" }} />
            </colgroup>
            <tbody className="border-0">
              <tr>
                <td className="item-details">
                  {!isEmpty(order?.items) &&
                    order?.items.map((item) => renderItem(item))}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    ));
  };

  const getAllOrders = useCallback((sortedOrders) => {
    const ordersArray = [];

    sortedOrders.forEach(([orderDate, orderData]) => {
      orderData.forEach((orderDetail) => {
        ordersArray.push(orderDetail);
      });
    });

    return ordersArray;
  }, []);

  // <----------------------------------------------------Sorting Functions ------------------------------------------------------------>

  const handleOnClickSort = (btnName) => {
    setActiveSortingField(btnName);
    setSortOrder((prev) => !prev);
    if (btnName === "order_time") {
      setIsToDisplayAllOrders(false);
    } else {
      setIsToDisplayAllOrders(true);
    }
  };

  const handleSort = useCallback((allOrders, sortField, sortOrder) => {
    if (sortField) {
      const sortedList = [...allOrders].sort((a, b) => {
        if (a[sortField] === undefined || null) return 1;
        if (b[sortField] === undefined || null) return -1;
        if (
          a[sortField] === undefined ||
          (null && b[sortField] === undefined) ||
          null
        ) {
          return 0;
        }
        if (sortField === "local_id") {
          let a_value = a[sortField];
          let b_value = b[sortField];

          a_value = a.local_id.replace(/^w/, "");
          b_value = b.local_id.replace(/^w/, "");

          return (+a_value - +b_value) * (sortOrder ? 1 : -1);
        }
        return -1;
      });
      return sortedList;
    }
  }, []);

  const getDataSortedByTime = useCallback((orderData, sortTimeOrder) => {
    const timeSorted = orderData.sort((a, b) => {
      const a_orderTimeMoment = moment(a?.orders_time).valueOf();
      const b_orderTimeMoment = moment(b?.orders_time).valueOf();

      const a_fullName = a.customer.first_name + a.customer.last_name;
      const b_fullName = b.customer.first_name + b.customer.last_name;
      if (a_orderTimeMoment !== b_orderTimeMoment)
        return (
          (a_orderTimeMoment - b_orderTimeMoment) * (sortTimeOrder ? 1 : -1)
        );
      else
        return (
          a_fullName
            .replace(/\s/g, "")
            .localeCompare(b_fullName.replace(/\s/g, "")) *
          (sortTimeOrder ? 1 : -1)
        );
    });
    return timeSorted;
  }, []);

  const handleTimeSort = useCallback(
    (orderData, sortOrder) => {
      if (orderData) {
        const sortedOrder = orderData.map(([dateSlot, orderDataArry]) => {
          const sortedTimeData = getDataSortedByTime(orderDataArry, sortOrder);
          return [dateSlot, sortedTimeData];
        });
        return sortedOrder;
      }
    },
    [getDataSortedByTime]
  );

  const getBoardItemCheck = useCallback(
    (orderDetail) =>
      orderDetail?.items.some((item) => {
        if (item?.cake_items) {
          return item?.cake_items.some(
            (cakeItem) =>
              itemInfo[cakeItem?.id] &&
              checkPhase(itemInfo[cakeItem.id], tiersData.tier_one, "board")
          );
        } else {
          return (
            itemInfo[item.id] &&
            checkPhase(itemInfo[item.id], tiersData.tier_one, "board")
          );
        }
      }),
    [itemInfo]
  );

  const handleBoardsFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return getBoardItemCheck(orderDetail);
        case 2:
          return !getBoardItemCheck(orderDetail);
        default:
          return true;
      }
    },
    [productionFilters, getBoardItemCheck]
  );

  const handleBakedFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return checkAllItemsBaked(orderDetail);
        case 2:
          return !checkAllItemsBaked(orderDetail);
        default:
          return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionFilters]
  );

  const getCompletedCheck = (orderDetail, status) =>
    orderDetail?.items.some((item) => getcurrentUserStatus(item) === status);

  const handleCompletedFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return getCompletedCheck(orderDetail, "completed");
        case 2:
          return getCompletedCheck(orderDetail, "default");
        case 3:
          return getCompletedCheck(orderDetail, "in-progress");
        default:
          return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionFilters]
  );

  const handleTierFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return orderDetail?.items.some(
            (item) => item?.product?.app_info?.ck_tiers === 1
          );
        case 2:
          return orderDetail?.items.some(
            (item) => item?.product?.app_info?.ck_tiers === 2
          );
        case 3:
          return orderDetail?.items.some(
            (item) => item?.product?.app_info?.ck_tiers >= 3
          );
        default:
          return true;
      }
    },
    [productionFilters]
  );

  const getShapeFilter = (orderDetail, shape) =>
    orderDetail?.items.some((item) => {
      if (item?.cake_items) {
        return item?.cake_items.some(
          (cakeItem) => cakeItem?.product?.app_info?.ck_shape === shape
        );
      } else {
        return item?.product?.app_info?.ck_shape === shape;
      }
    });

  const handleShapeFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return getShapeFilter(orderDetail, "rnd");
        case 2:
          return getShapeFilter(orderDetail, "sqr");
        case 3:
          return getShapeFilter(orderDetail, "sht");
        default:
          return true;
      }
    },
    [productionFilters]
  );

  const getWhiteIcedColor = (orderDetail) =>
    orderDetail.items.some((item) => {
      if (item?.cake_items) {
        return item?.cake_items.some((cakeItem) =>
          cakeItem.modifieritems.some(
            (modiItem) =>
              modiItem.modifier.modifierCat === modifierCatData?.iced_color &&
              modiItem?.modifier?.id === modifierIdData?.white_iced_color
          )
        );
      } else {
        return item.modifieritems.some(
          (modiItem) =>
            modiItem.modifier.modifierCat === modifierCatData?.iced_color &&
            modiItem?.modifier?.id === modifierIdData?.white_iced_color
        );
      }
    });

  const handleFlavorFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return getWhiteIcedColor(orderDetail);
        case 2:
          return !getWhiteIcedColor(orderDetail);
        case 3:
          return orderDetail.items.some((item) => {
            if (item?.cake_items) {
              return item?.cake_items.some((cakeItem) =>
                cakeItem.modifieritems.some(
                  (modiItem) =>
                    modiItem.modifier.modifierCat ===
                      modifierCatData?.iced_color &&
                    modifierIdData?.vegan_iced.includes(modiItem?.modifier?.id)
                )
              );
            } else {
              return item.modifieritems.some(
                (modiItem) =>
                  modiItem.modifier.modifierCat ===
                    modifierCatData?.iced_color &&
                  modifierIdData?.vegan_iced.includes(modiItem?.modifier?.id)
              );
            }
          });
        default:
          return true;
      }
    },
    [productionFilters]
  );

  const getFillingIcedFilter = useCallback(
    (orderDetail, key) => {
      return orderDetail?.items.some((item) => {
        if (item?.cake_items) {
          return item.cake_items.some((cakeItem) =>
            cakeItem?.modifieritems.some(
              (modiItem) =>
                +modiItem?.modifier?.id === +currentdropDownValue[key]
            )
          );
        } else {
          return item?.modifieritems.some(
            (modiItem) => +modiItem?.modifier?.id === +currentdropDownValue[key]
          );
        }
      });
    },
    [currentdropDownValue]
  );

  const handleFillingsFilter = useCallback(
    (key, orderDetail) => {
      switch (productionFilters[key]) {
        case 0:
          return true;
        case 1:
          return getFillingIcedFilter(orderDetail, key);
        default:
          return true;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionFilters, currentdropDownValue]
  );

  const getProductionFilteredOrders = useCallback(
    (orders) => {
      const filterKeys = Object.keys(productionFilters);
      const filteredOrders = orders.filter(([orderId, orderDetail]) => {
        const checkEvery = filterKeys.every((key) => {
          switch (key) {
            case "boards":
              return handleBoardsFilter(key, orderDetail);
            case "baked":
              return handleBakedFilter(key, orderDetail);
            case "completed":
              return handleCompletedFilter(key, orderDetail);
            case "tierCakes":
              return handleTierFilter(key, orderDetail);
            case "shapeCakes":
              return handleShapeFilter(key, orderDetail);
            case "flavorCakes":
              return handleFlavorFilter(key, orderDetail);
            case "fillings":
            case "iced":
              return handleFillingsFilter(key, orderDetail);
            default:
              return true;
          }
        });
        return checkEvery;
      });
      return filteredOrders;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionFilters, currentdropDownValue]
  );

  const handleFilteredOrders = useCallback(
    (displayOrdersByDate) => {
      if (!isEmpty(displayOrdersByDate)) {
        const filteredOrders = getCheckboxFilteredOrder(
          displayOrdersByDate,
          getProductionFilteredOrders
        );

        return filteredOrders;
      }
      return displayOrdersByDate;
    },
    [getProductionFilteredOrders]
  );

  // <-----------------------------------------------------Use Memo Functions ---------------------------------------------------------->

  const getSimplifiedOrders = (ordersByObj) =>
    Object.entries(ordersByObj).map(([orderDate, orderData]) => [
      orderDate,
      Object.values(orderData.orders),
    ]);

  const checkBoxFilterOrders = useMemo(
    () => getSimplifiedOrders(handleFilteredOrders(displayOrdersByDate)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productionFilters, displayOrdersByDate, currentdropDownValue, itemInfo]
  );

  const sortedAllOrders = useMemo(() => {
    const allOrders = getAllOrders(checkBoxFilterOrders);
    if (activeSortingField === "order_time") {
      return handleTimeSort(checkBoxFilterOrders, sortOrder);
    } else {
      return handleSort(allOrders, activeSortingField, sortOrder);
    }
  }, [
    activeSortingField,
    sortOrder,
    checkBoxFilterOrders,
    getAllOrders,
    handleTimeSort,
    handleSort,
  ]);

  const handleIsEmptyOrders = (selectedOrdersView) => {
    if (isToDisplayAllOrders) {
      return !isEmpty(selectedOrdersView);
    } else {
      return selectedOrdersView.some(
        ([dateSlot, orderData]) => !isEmpty(orderData)
      );
    }
  };

  const selectedOrdersView = isToDisplayAllOrders
    ? sortedAllOrders
    : checkBoxFilterOrders;

  return (
    <Table
      responsive
      className="editable-table custom-table-striped production"
    >
      <colgroup>
        <col width={240} />
        <col style={{ minWidth: "1050px" }} />
      </colgroup>
      <thead className="border-0 ">
        <tr>
          <th className="d-flex">
            {sortButtonsData.map((data, index) => (
              <React.Fragment key={data.name}>
                <SortButton
                  activeSortingField={activeSortingField}
                  btnName={data.name}
                  onClickSort={handleOnClickSort}
                  btnLabel={data.label}
                />
              </React.Fragment>
            ))}
          </th>
          <th>
            <table className="w-100 table-fixed">
              <colgroup>
                <col width={180} />
                <col style={{ minWidth: "650px" }} />
                <col width={200} />
              </colgroup>
              <thead>
                <tr>
                  <th>Boards</th>
                  <th>
                    Size/Shape | Flavor / Filling(s) /// Iced Color // Iced /
                    Borders
                  </th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(selectedOrdersView) &&
        handleIsEmptyOrders(selectedOrdersView) ? (
          !isToDisplayAllOrders ? (
            selectedOrdersView.map(
              ([dateSlot, orderData]) =>
                !isEmpty(orderData) && (
                  <React.Fragment key={dateSlot}>
                    <tr className="slot-header">
                      <th colSpan={2} className="ps-4">
                        {moment(dateSlot).format(displayHeaderDateFormat)}
                      </th>
                    </tr>
                    {renderOrders(orderData)}
                  </React.Fragment>
                )
            )
          ) : (
            renderOrders(selectedOrdersView)
          )
        ) : (
          <tr className="bg-white">
            <td colSpan={4}>
              <NoData />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default Production;
