import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

let startdate = moment().format("YYYY-MM-DD");
let day = 1;
const useCalenderSearchQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get("startDate")) {
    startdate = searchParams.get("startDate");
  }
  if (searchParams.get("days")) {
    day = searchParams.get("days");
  }

  const [calenderDates, setCalenderDates] = useState({
    startDate: startdate,
    endDate: moment(startdate).add(day, "days").format("YYYY-MM-DD"),
    days: day,
  });

  useEffect(() => {
    setSearchParams({
      startDate: calenderDates.startDate,
      days: calenderDates.days,
    });
  }, [calenderDates, setSearchParams]);

  return [calenderDates, setCalenderDates];
};

export default useCalenderSearchQuery;
